import { useCallback, useEffect, useState } from 'react'

import useScriptLoader, { ScriptStatus } from '../../../hooks/useScriptLoader'

export interface JIRAIssueCollectorOptions {
  issueSummary?: string | null
  collectorId?: string | null
  locale?: string | null

  epicName?: string | null
  assignee?: string | null
}

interface PluginWindowOptions {
  triggerFunction: (showCollectorDialog: () => void) => void
  fieldValues: {
    /** Name of the job position */
    summary: string

    /** Current URL */
    customfield_12100: string
    /** Epic Name */
    customfield_10004?: string
    assigne?: {
      name: string
    }
  }
}

const defaultLocale = 'en-US'
const defaultCollectorId = '60862d4a'

export function useIssueCollectorScript(options: JIRAIssueCollectorOptions) {
  const locale = options.locale ?? defaultLocale
  const collectorId = options.collectorId ?? defaultCollectorId

  const jqueryScriptSrc = `https://jira.kruso.dk/s/d41d8cd98f00b204e9800998ecf8427e-CDN/u93s67/802003/9da6d64f11b6843f4b22275f980b17dd/2.2.4.7/_/download/batch/com.atlassian.plugins.jquery:jquery/com.atlassian.plugins.jquery:jquery.js?collectorId=${collectorId}`
  const jqueryScriptStatus = useScriptLoader(jqueryScriptSrc)

  const pluginScriptSrc =
    jqueryScriptStatus === ScriptStatus.Ready
      ? `https://jira.kruso.dk/s/b83c55a3095efe6d2fdd3921484b735e-T/sr2t18/76004/9da6d64f11b6843f4b22275f980b17dd/2.0.24/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=${locale}&collectorId=${collectorId}`
      : undefined
  const pluginScriptStatus = useScriptLoader(pluginScriptSrc)

  const [showCollectorDialog, setShowCollectorDialog] = useState<
    (() => void) | null
  >(null)

  const isReady = showCollectorDialog !== null

  useEffect(() => {
    const pluginWindowOptions: PluginWindowOptions = {
      triggerFunction(_showCollectorDialog) {
        // Passing _showCollectorDialog directly will make useState call it
        setShowCollectorDialog(() => _showCollectorDialog)
      },
      fieldValues: {
        summary: options.issueSummary ?? 'Job position',
        customfield_12100: document.URL,
      },
    }

    if (options.epicName) {
      pluginWindowOptions.fieldValues.customfield_10004 = options.epicName
    }

    if (options.assignee) {
      pluginWindowOptions.fieldValues.assigne = { name: options.assignee }
    }

    window.ATL_JQ_PAGE_PROPS = pluginWindowOptions
  }, [options])

  useEffect(() => {
    if (jqueryScriptStatus === ScriptStatus.Error) {
      window.console.error(
        'Failed to load jQuery script for JIRA Issue Collector plugin.'
      )
    }

    if (pluginScriptStatus === ScriptStatus.Error) {
      window.console.error(
        'Failed to load plugin script for JIRA Issue Collector.'
      )
    }
  }, [jqueryScriptStatus, pluginScriptStatus])

  const triggerIssueCollector = useCallback(
    function triggerIssueCollector() {
      if (!showCollectorDialog) {
        return
      }

      showCollectorDialog()
    },
    [showCollectorDialog]
  )

  return {
    isReady,
    triggerIssueCollector,
  }
}
