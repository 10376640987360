import { ContentfulEmployee } from '../../@import-types/contentful/Employee.types'
import { getShouldLinkToEmployee } from '../../utils/employeeUtils'
import useGlobalStartPage from '../globalContent/useGlobalStartPage'

import usePageLink from './usePageLink'

export default function useEmployeeLink(employeeContent: ContentfulEmployee) {
  const globalStartPage = useGlobalStartPage()
  const employeeLink = usePageLink(
    globalStartPage?.aboutPage?.slug,
    employeeContent.slug
  )

  return getShouldLinkToEmployee(employeeContent) ? employeeLink : null
}
