import classNames from 'classnames'
import { graphql } from 'gatsby'
import React from 'react'

import { CaseContent } from '../../../@import-types/contentful/Case.types'
import useIsDesktop, { useIsDesktopLarge } from '../../../hooks/useIsDesktop'
import CaseTeaserCard from '../../_cards/CaseTeaserCard'
import TeaserCardSwitch from '../../_cards/TeaserCardSwitch'
import ContentfulRichText from '../../_elements/ContentfulRichText'
import LayoutContainer from '../../_layouts/LayoutContainer'
import RichTextLayout from '../../_layouts/RichTextLayout'
import Heading2 from '../../_typography/Heading2'
import Img from 'gatsby-image'

import styles from './tiles-block.module.scss'
import { ContentfulAsset } from '../../../@import-types/contentful/Asset.types'

export const TilesBlockTypename = 'ContentfulBlockTiles'

export interface TilesBlockProps {
  __typename: typeof TilesBlockTypename

  title: string | null | undefined
  bodyText: null | undefined | { json: any }

  entry: CaseContent | null | undefined
  entryToLeft: boolean | null | undefined
  image?: ContentfulAsset | null
}

export default function TilesBlock({
  title,
  bodyText,

  entry,
  entryToLeft,
  image,
}: TilesBlockProps) {
  const isDesktop = useIsDesktop()
  const isDesktopLarge = useIsDesktopLarge()

  const hasImage = Boolean(image && image.fluid)

  return (
    <LayoutContainer
      className={classNames(
        styles.tilesContainer,
        !entryToLeft && styles.teaserToTheRight,
        !entry && styles.noEntry
      )}
      withMargin
    >
      {entry && !hasImage && (
        <TeaserCardSwitch className={styles.caseTeaser} teaser={entry} />
        )}

      {hasImage && (
        <Img
        className={styles.caseTeaser}
        fluid={image!.fluid}
        alt={image!.description ?? image!.title ?? ""}
        />
        )}
      <RichTextLayout
        className={styles.textContainer}
        smallerMargins={isDesktop && !isDesktopLarge}
      >
        {title && 
        <Heading2>{title}</Heading2>
        }

        {bodyText && <ContentfulRichText json={bodyText.json} />}
      </RichTextLayout>
    </LayoutContainer>
  )
}

export const ProjectFragment = graphql`
  fragment TilesBlockFragment on Node {
    ... on ContentfulBlockTiles {
      title

      bodyText {
        json
      }

      
      image {
        title
        description
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }

      entry {
        ...CaseTeaserFragment
        ...BlogPostTeaserFragment
        ...StandardPageTeaserFragment
      }

      entryToLeft

    }
  }
`
