import classNames from 'classnames'
import React from 'react'

import styles from './base-form.module.scss'

interface BaseFormProps {}

export default function BaseForm({
  className,
  ...formProps
}: BaseFormProps & JSX.IntrinsicElements['form']) {
  return (
    <form className={classNames(styles.baseForm, className)} {...formProps} />
  )
}
