import classNames from 'classnames'
import Img from 'gatsby-image'
import React from 'react'

import { ContentfulEmployee } from '../../../@import-types/contentful/Employee.types'
import useEmployeeLink from '../../../hooks/routing/useEmployeeLink'
import { getComplementaryColor } from '../../../utils/colorUtils'
import { getEmployeeTeaserImage } from '../../../utils/employeeUtils'
import BlobContainerZoomIn from '../../_layouts/BlobContainerZoomIn'

import styles from './employee-card.module.scss'

interface EmployeeCardProps {
  employee: ContentfulEmployee

  className?: string
}

export default function EmployeeCard({
  employee,

  className,
}: EmployeeCardProps) {
  const employeeLink = useEmployeeLink(employee)
  const teaserImage = getEmployeeTeaserImage(employee)
  const fluidImage = teaserImage?.fluid

  return (
    <BlobContainerZoomIn
      className={classNames(styles.container, className)}
      to={employeeLink}
      blobOptions={{ radius: 200 }}
      employee={employee}
    >
      {(maskId, isMaskActive) => (
        <>
          {fluidImage && (
            <Img
              className={styles.image}
              fluid={fluidImage}
              alt={teaserImage!.description || teaserImage!.title}
            />
          )}

          <div className={styles.textsContainer}>
            <div className={styles.role} role="doc-subtitle">
              {employee.role}
            </div>

            <h3 className={styles.name}>
              <div>{employee.firstName}</div>
              <div>{employee.lastName}</div>
            </h3>
          </div>

          {isMaskActive && (
            <div
              className={styles.maskContainer}
              style={{
                backgroundColor: employee.color,
                color: getComplementaryColor(employee.color),
                clipPath: `url(#${maskId})`,
              }}
            >
              <div className={styles.role} role="doc-subtitle">
                {employee.role}
              </div>

              <h3 className={styles.name}>
                <div>{employee.firstName}</div>
                <div>{employee.lastName}</div>
              </h3>
            </div>
          )}
        </>
      )}
    </BlobContainerZoomIn>
  )
}
