import classNames from 'classnames'
import { graphql } from 'gatsby'
import React from 'react'

import LayoutContainer from '../../_layouts/LayoutContainer'

import styles from './divider-block.module.scss'

export const DividerBlockTypename = 'ContentfulBlockDivider'

export interface DividerBlockProps {
  __typename: typeof DividerBlockTypename
  removeMarginTop?: boolean | null
  removeMarginBottom?: boolean | null
}

export default function DividerBlock({
  removeMarginBottom,
  removeMarginTop,
}: DividerBlockProps) {
  return (
    <LayoutContainer
      className={classNames(
        styles.container,
        removeMarginTop && styles.removeMarginTop,
        removeMarginBottom && styles.removeMarginBottom
      )}
      withMargin
    />
  )
}

export const DividerBlockFragment = graphql`
  fragment DividerBlockFragment on Node {
    ... on ContentfulBlockDivider {
      __typename
      removeMarginTop
      removeMarginBottom
    }
  }
`
