import classNames from 'classnames'
import { graphql } from 'gatsby'
import React, { useState } from 'react'

import { ContentfulAsset } from '../../../@import-types/contentful/Asset.types'
import useMicroCopy from '../../../hooks/globalContent/useMicroCopy'
import useIsDesktop from '../../../hooks/useIsDesktop'
import VideoCard from '../../_cards/VideoCard'
import ContentfulRichText from '../../_elements/ContentfulRichText'
import Video from '../../_elements/Video'
import BaseCarousel from '../../_layouts/BaseCarousel'
import LayoutContainer from '../../_layouts/LayoutContainer'
import RichTextLayout from '../../_layouts/RichTextLayout'
import SvgArrowDown from '../../_svg/SvgArrowDown'
import Heading2 from '../../_typography/Heading2'

import styles from './video-collection-block.module.scss'

export const VideoCollectionBlockTypename = 'ContentfulBlockVideoCollection'

interface video {
  id: string
  title?: string
  topic?: string | null
  topicSubtitle?: string | null
  video: ContentfulAsset | null
  posterImage?: ContentfulAsset | null
}

export interface VideoCollectionBlockProps {
  __typename: typeof VideoCollectionBlockTypename

  title: string | null
  description?: {
    json: any
  }

  collection: Array<video>
}

export default function VideoCollectionBlock({
  title,
  description,

  collection,
}: VideoCollectionBlockProps) {
  const isDesktop = useIsDesktop()
  const controlsText = useMicroCopy('text.seeMore')
  const next = 'next'
  const prev = 'prev'

  const [videoCollection, setVideoCollection] = useState(collection)

  const handleControlsClick = (direction: string) => {
    let array = [...videoCollection]
    let video
    if (direction === next) {
      video = array.shift()
      array.push(video!)
    }
    if (direction === prev) {
      video = array.pop()
      array.unshift(video!)
    }
    setVideoCollection(array)
  }

  const handleVideoClick = (index:number) => {
    let array = [...videoCollection]
    let video
    for (let i=0; i<index;i++) {
      video = array.shift()
      array.push(video!)
    }
    setVideoCollection(array)
  }

  return (
    <LayoutContainer
      className={classNames(styles.videoBlockContainer)}
      withMargin
    >
      <Heading2 className={styles.title}>{title}</Heading2>
      {description && (
        <RichTextLayout className={styles.description}>
          <ContentfulRichText json={description.json} />
        </RichTextLayout>
      )}
      {isDesktop && (
        <>
          <Video video={videoCollection[0]} />
          <div className={styles.videoSelector}>
            <div className={styles.controlsContainer}>
              <span className={styles.controlsText}>{controlsText}</span>
              <div className={styles.arrowControls}>
                <button
                  onClick={() => handleControlsClick(prev)}
                  className={styles.arrowControlsLeft}
                >
                  <SvgArrowDown />
                </button>
                <button
                  onClick={() => handleControlsClick(next)}
                  className={styles.arrowControlsRight}
                >
                  <SvgArrowDown />
                </button>
              </div>
            </div>
            <div className={styles.videoCollection}>
              {videoCollection.slice(0, 3).map((video, index) => (
                <button onClick={() => handleVideoClick(index)}>
                <VideoCard video={video} key={video.id} />
                </button>
              ))}
            </div>
          </div>
        </>
      )}
      {!isDesktop && (
        <BaseCarousel
          withMargin={false}
          withScrollbar={true}
          keepItemContainerCentered={true}
        >
          {videoCollection.map((video) => (
            <li key={video.id} className={styles.mobileVideoContainer}>
              <Video video={video} />
            </li>
          ))}
        </BaseCarousel>
      )}
    </LayoutContainer>
  )
}

export const videoCollectionBlockFragment = graphql`
  fragment VideoCollectionBlockFragment on Node {
    ... on ContentfulBlockVideoCollection {
      id
      title
      description {
        json
      }

      collection {
        id
        video {
          file {
            url
          }
        }
        title
        topic
        topicSubtitle
        color
        posterImage {
          title
          description
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`
