import { BasePageContent } from './BasePage.types'
import { Office } from './Office.types'

export const JobRolePageTypename = 'ContentfulJobRolePage'

export interface JobRolePageContent
  extends BasePageContent<typeof JobRolePageTypename> {
  title?: string | null
  slug?: string | null

  teaserTitle?: string | null

  applyBeforeDate?: string | null

  office?: Office | null

  content?: any[] | null
}
