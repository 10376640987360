import { graphql } from 'gatsby'
import React, { useEffect } from 'react'
import { CTATargetId } from '../../../constants/GlobalDOM'
import HubspotForm from '../../HubspotForm/HubspotForm'
import ContentfulRichText from '../../_elements/ContentfulRichText'
import LayoutContainer from '../../_layouts/LayoutContainer'

import styles from './hubspot-form-block.module.scss'

export const HubspotFormBlockTypename = 'ContentfulBlockHubspotForm'

export interface HubspotFormBlockProps {
  __typename: typeof HubspotFormBlockTypename

  title?: string
  bodyText?: {
    json: any
  }
  formId: string
  target: string
  slug: string
  identifyTraits?:Array<string>
}

export default function HubspotFormBlock({
  title,
  bodyText,
  formId,
  target,
  slug,
  identifyTraits
}: HubspotFormBlockProps) {
  return (
    <LayoutContainer withMargin>
      <section className={styles.hubspotForm} id={slug}>
        <div className={styles.textWrapper} >
          <h2>{title}</h2>
          {bodyText && <ContentfulRichText json={bodyText.json} />}
        </div>
        <div className={styles.formWrapper} id={CTATargetId}>
          <HubspotForm
            formId={formId}
            target={target}
            identifyTraits={identifyTraits}
            gtmEvent="form_submitted"
          />
        </div>
      </section>
    </LayoutContainer>
  )
}

export const HubspotFormBlockFragment = graphql`
  fragment HubspotFormBlockFragment on Node {
    ... on ContentfulBlockHubspotForm {
      title
      bodyText {
        json
      }
      formId
      target
      slug
      identifyTraits
    }
  }
`
