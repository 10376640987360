import { ContentfulAsset } from './Asset.types'
import { BasePageContent } from './BasePage.types'
import { RichTextNode } from './general.types'

export const StandardPageTypeName = 'ContentfulStandardPage'

export interface StandardPageType
  extends BasePageContent<typeof StandardPageTypeName> {
  title?: string | null
  heroImage?: ContentfulAsset | null
  heroRichText?: RichTextNode | null
  slug: string
  navigationTitle?: string | null
  teaserTitle?: string | null
  teaserDescription?: string | null
  teaserColor?: string | null
  visibleInMenu?: boolean | null

  teaserImage?: ContentfulAsset | null
  heroPoster?: ContentfulAsset | null

  content?: any[] | null
  pages?: StandardPageType[]
}
