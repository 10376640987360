import { graphql } from 'gatsby'
import React from 'react'

import Blockquote from '../../_elements/Blockquote'
import LayoutContainer from '../../_layouts/LayoutContainer'

export const QuoteBlockTypename = 'ContentfulBlockQuote'

export interface QuoteBlockProps {
  __typename: typeof QuoteBlockTypename

  quote?: {
    quote?: string
  } | null

  author?: string | null
  context?: string | null
}

export default function QuoteBlock({
  quote,

  author,
  context,
}: QuoteBlockProps) {
  return (
    <LayoutContainer withMargin>
      <Blockquote quote={quote?.quote} author={author} context={context} />
    </LayoutContainer>
  )
}

export const QuoteBlockFragment = graphql`
  fragment QuoteBlockFragment on Node {
    ... on ContentfulBlockQuote {
      quote {
        quote
      }

      author
      context
    }
  }
`
