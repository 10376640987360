import { leadingZeroTenths } from './stringUtils'

type BaseDateTimeFormatValues = 'numeric' | '2-digit'

export function formatLocaleDate(
  locale: string,
  date: Date | string,
  options?: Intl.DateTimeFormatOptions & {
    day?: BaseDateTimeFormatValues
    month?: BaseDateTimeFormatValues | 'long' | 'short' | 'narrow'
    year?: BaseDateTimeFormatValues
    hour?: BaseDateTimeFormatValues
    minute?: BaseDateTimeFormatValues
    second?: BaseDateTimeFormatValues
  }
) {
  return new Date(date).toLocaleDateString(locale, options)
}

export function formatMonthDate(locale: string, date: Date | string) {
  return formatLocaleDate(locale, date, {
    month: 'long',
    year: 'numeric',
  })
}

export function formatHtmlDate(date: Date | string) {
  date = typeof date === 'string' ? new Date(date) : date

  const year = date.getFullYear()
  const month = leadingZeroTenths(String(date.getMonth() + 1))
  const day = leadingZeroTenths(String(date.getDate()))

  return `${year}-${month}-${day}`
}
