import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

/** *
 * @param formName This name needs to be registered with recaptcha.
 */
export default function useRecaptchaHeaders(formName: string) {
  const { executeRecaptcha } = useGoogleReCaptcha()

  return async function fetchRecaptchaHeaders() {
    if (!executeRecaptcha) {
      return null
    }

    const recaptchaResult = await executeRecaptcha(formName)

    return {
      'recaptcha-response': recaptchaResult,
      'recaptcha-action': 'action',
    }
  }
}
