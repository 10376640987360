import classNames from 'classnames'
import React, { ReactNode } from 'react'

import styles from './side-column-layout.module.scss'

interface SideColumnLayoutProps {
  sideColumn: ReactNode | ReactNode[]
  mainColumn: ReactNode | ReactNode[]

  sideColumnClassName?: string | null | boolean
  mainColumnClassName?: string | null | boolean
}

export default function SideColumnLayout({
  sideColumn,
  mainColumn,

  className,
  sideColumnClassName,
  mainColumnClassName,

  ...divProps
}: SideColumnLayoutProps & JSX.IntrinsicElements['div']) {
  return (
    <div className={classNames(styles.container, className)} {...divProps}>
      {sideColumn && (
        <div className={classNames(styles.sideColumn, sideColumnClassName)}>
          {sideColumn}
        </div>
      )}
      <div className={classNames(styles.mainColumn, mainColumnClassName)}>
        {mainColumn}
      </div>
    </div>
  )
}
