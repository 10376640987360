import React from 'react'

import { AboutPageTypename } from '../../../@import-types/contentful/AboutPage.types'
import { BlogListingPageTypename } from '../../../@import-types/contentful/BlogListingPage.types'
import { BlogPostTypename } from '../../../@import-types/contentful/BlogPost.types'
import { CaseTypename } from '../../../@import-types/contentful/Case.types'
import { JobRolePageTypename } from '../../../@import-types/contentful/JobRolePage.types'
import { OurWorkPageTypename } from '../../../@import-types/contentful/OurWorkPage.types'
import { StandardPageTypeName } from '../../../@import-types/contentful/StandardPage.types'
import { AnyTeaser } from '../../../@import-types/contentful/general.types'
import useTeaserLink from '../../../hooks/routing/useTeaserLink'
import { getColorByName } from '../../../utils/colorUtils'
import BlobTeaserCard from '../BlobTeaserCard'
import BlogPostTeaserCard from '../BlogPostTeaserCard'
import CaseTeaserCard from '../CaseTeaserCard'
import JobTeaserCard from '../JobTeaserCard'
import StandardPage from '../../../templates/StandardPage/StandardPage'
import StandardPageTeaserCard from '../StandardPageTeaserCard'

interface TeaserCardSwitchProps {
  teaser: AnyTeaser

  className?: string
}

export default function TeaserCardSwitch({
  teaser,
  className,
}: TeaserCardSwitchProps) {
  const teaserLink = useTeaserLink(teaser)

  switch (teaser.__typename) {
    case AboutPageTypename:
    case BlogListingPageTypename:
    case OurWorkPageTypename:
    case StandardPageTypeName:
      return (
        <BlobTeaserCard
          className={className}
          to={teaserLink}
          title={teaser.teaserTitle ?? teaser.title}
          description={teaser.teaserDescription}
          backgroundColor={getColorByName(teaser.teaserColor)}
          blobImage={teaser.teaserImage}
        />
      )

    case BlogPostTypename:
      return <BlogPostTeaserCard className={className} blogPost={teaser} />

    case CaseTypename:
      return <CaseTeaserCard className={className} caseContent={teaser} />

    case JobRolePageTypename:
      return <JobTeaserCard className={className} job={teaser} />

    case StandardPageTypeName:
      return (
        <StandardPageTeaserCard className={className} standardPage={teaser} />
      )

    default:
      return null
  }
}
