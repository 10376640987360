import classNames from 'classnames'
import React from 'react'

import { ContentfulEmployee } from '../../../@import-types/contentful/Employee.types'
import useMicroCopy from '../../../hooks/globalContent/useMicroCopy'
import EmployeeCard from '../../_cards/EmployeeCard'
import Heading2 from '../../_typography/Heading2'
import LayoutContainer from '../LayoutContainer'

import styles from './employee-grid.module.scss'

interface EmployeeGridProps {
  title?: string | null

  employees: ContentfulEmployee[]

  htmlId?: string | null
  className?: string
}

export default function EmployeeGrid({
  title,

  employees,

  htmlId,
  className,
}: EmployeeGridProps) {
  const meetTheKrusadersLabel = useMicroCopy('text.meet_the_krusaders')

  const renderTitle = title ?? meetTheKrusadersLabel

  return (
    <LayoutContainer
      className={classNames(styles.container, className)}
      withMargin
    >
      {renderTitle && (
        <Heading2 className={styles.heading} id={htmlId ?? 'krusaders'}>
          {renderTitle}
        </Heading2>
      )}

      <ul className={styles.list}>
        {employees.map((employee) => (
          <li key={employee.id} className={styles.item}>
            <EmployeeCard employee={employee} />
          </li>
        ))}
      </ul>
    </LayoutContainer>
  )
}
