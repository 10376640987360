import React from 'react'

interface SvgArrowRightSmallProps {}

export default function SvgArrowRightSmall(
  props: SvgArrowRightSmallProps & JSX.IntrinsicElements['svg']
) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" {...props}>
      <path
        className="svg-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4108 15.163L25 14.5002L24.4108 13.8373L18.3337 7L17.1552 8.32559L21.8098 13.5625L5 13.5625L5 15.4373L21.81 15.4373L17.1543 20.6743L18.3327 22L24.4108 15.163Z"
        fill="#2f210c"
      />
    </svg>
  )
}
