import classNames from 'classnames'
import React, { MutableRefObject, ReactElement } from 'react'

import useFormElementId from '../../../../hooks/forms/useFormElementId'
import useMicroCopy from '../../../../hooks/globalContent/useMicroCopy'

import styles from './base-form-field.module.scss'

export interface BaseFormFieldPublicProps {
  label: string | null | undefined

  isOptional?: boolean
}

interface BaseFormFieldProps extends BaseFormFieldPublicProps {
  id?: string | null
  name?: string | null
  value: string | number | readonly string[] | undefined | null

  formFieldRef?:
    | MutableRefObject<HTMLInputElement>
    | MutableRefObject<HTMLTextAreaElement>

  className?: string

  children: ReactElement<{ className?: string }>
}

export default function BaseFormField({
  label,

  id,
  name,
  value,

  isOptional,

  formFieldRef,

  className,

  children: formFieldChild,

  ...divProps
}: BaseFormFieldProps & JSX.IntrinsicElements['div']) {
  const optionalLabel = useMicroCopy('text.optional')
  const labelFor = useFormElementId(id, name, label)

  const hasValue = Boolean(value) || Boolean(formFieldRef?.current?.value)

  return (
    <div
      className={classNames(
        styles.container,
        hasValue && styles.hasValue,
        className
      )}
      {...divProps}
    >
      {React.cloneElement(formFieldChild, {
        className: classNames(styles.formField, formFieldChild.props.className),
      })}

      <label className={styles.label} htmlFor={labelFor}>
        {label}
      </label>

      {isOptional && !hasValue && (
        <span className={styles.optionalLabel}>{optionalLabel}</span>
      )}
    </div>
  )
}
