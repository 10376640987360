import classNames from 'classnames'
import React from 'react'

import { JobRolePageContent } from '../../../@import-types/contentful/JobRolePage.types'
import JobTeaserCard from '../../_cards/JobTeaserCard'
import Heading2 from '../../_typography/Heading2'
import LayoutContainer from '../LayoutContainer'

import styles from './job-teasers-grid.module.scss'

interface JobTeasersGridProps {
  title?: string | null

  jobs: JobRolePageContent[]

  className?: string
}

export default function JobTeasersGrid({
  title,
  jobs,

  className,
}: JobTeasersGridProps) {
  return (
    <LayoutContainer
      className={classNames(styles.container, className)}
      withMargin
    >
      {title && <Heading2 className={styles.title}>{title}</Heading2>}

      <div className={styles.teasersContainer}>
        {jobs.map((job) => (
          <JobTeaserCard key={job.id} className={styles.jobTeaser} job={job} />
        ))}
      </div>
    </LayoutContainer>
  )
}
