import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

import { ContentfulAsset } from '../../../@import-types/contentful/Asset.types'
import LayoutContainer from '../../_layouts/LayoutContainer'

import styles from './gallery-block.module.scss'
import BaseCarousel from '../../_layouts/BaseCarousel'

export const GalleryBlockTypename = 'ContentfulBlockGallery'

export interface GalleryBlockProps {
  __typename: typeof GalleryBlockTypename
  title?: string | null
  gallery: ContentfulAsset[]
}

export default function GalleryBlock({ title, gallery }: GalleryBlockProps) {
  return (
    <LayoutContainer className={styles.container} withMargin>
        <BaseCarousel>
        {gallery.map(
          (item, index) =>
            item &&
            item.fluid && (
              <div key={index} className={styles.item}>
                <Img
                  className={styles.image}
                  fluid={item.fluid}
                  alt={item.description || item.title || ""}
                />
              </div>
            )
        )}
      </BaseCarousel>
    </LayoutContainer>
  )
}

export const GalleryBlockFragment = graphql`
  fragment GalleryBlockFragment on Node {
    ... on ContentfulBlockGallery {
      title

      gallery {
        title
        description
        fluid(quality: 90, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`
