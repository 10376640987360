import React, { useMemo } from 'react'

import useLocale from '../../../hooks/globalContent/useLocale'
import { formatHtmlDate, formatLocaleDate } from '../../../utils/dateUtils'

interface BaseDateProps {
  date: Date | string | null | undefined

  formatDate?: (locale: string, date: Date) => string
}

export default function BaseDate({
  date,

  formatDate = formatLocaleDate,

  ...timeElProps
}: BaseDateProps & Omit<JSX.IntrinsicElements['time'], 'dateTime'>) {
  const locale = useLocale()

  const dateInstance = useMemo(() => {
    if (!date) {
      return null
    }

    if (typeof date === 'string') {
      return new Date(date)
    }

    return date
  }, [date])

  if (!dateInstance) {
    return null
  }

  return (
    <time dateTime={formatHtmlDate(dateInstance)} {...timeElProps}>
      {formatDate(locale, dateInstance)}
    </time>
  )
}
