import classNames from 'classnames'
import React from 'react'

import styles from './checkbox-field.module.scss'

interface CheckboxFieldProps {
  id: string
  label: string | React.ReactNode
  className?: string
  value?: string
  name?: string
}

export default function CheckboxField({
  id,
  label,
  className,
  value,
  name,
  ...inputProps
}: CheckboxFieldProps & JSX.IntrinsicElements['input']) {
  return (
    <div className={classNames(styles.checkboxField, className)}>
      <input
        className={styles.checkbox}
        type="checkbox"
        id={id}
        value={value}
        name={name}
        {...inputProps}
      />
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
    </div>
  )
}
