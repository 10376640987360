import { useState } from 'react'

import useRecaptchaHeaders from '../../../hooks/forms/useRecaptchaHeaders'

import { FormOptions } from './ApplyToJobFormBlock'

interface FormValues {
  name: string
  email: string
  phone: string
  portfolio: string
  attachment_1: File | null
  attachment_2: File | null
  description: string
}

const apiUrl = process.env.GATSBY_APPLY_FORM_API_URL
const apiKey = process.env.GATSBY_MAIL_API_KEY
const siteUrl = process.env.GATSBY_HOST
const formRecaptchaName =
  process.env.GATSBY_APPLY_FORM_RECAPTCHA_NAME ?? 'applynow'

const defaultProjectKey = 'REC'
const defaultIssueSummary = 'Job Position'

export function useApplyFormInputProps() {
  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    email: '',
    phone: '',
    portfolio: '',
    attachment_1: null,
    attachment_2: null,
    description: '',
  })

  function onInputChange(
    eventOrName:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | string,
    fileOrNothing?: File | null
  ) {
    let name, value

    if (typeof eventOrName === 'string') {
      name = eventOrName
      value = fileOrNothing
    } else {
      name = eventOrName.currentTarget.name
      value = eventOrName.currentTarget.value
    }

    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const inputProps: Record<
    keyof typeof formValues,
    { name: string; value: string | undefined; onChange: typeof onInputChange }
  > = {}

  for (let name in formValues) {
    const typedName = name as keyof typeof formValues

    inputProps[typedName] = {
      name,
      value: formValues[typedName],
      onChange: onInputChange,
    }
  }

  return { ...inputProps, getValues: () => formValues }
}

export function useFormSendState(options: FormOptions) {
  const [isSending, setIsSending] = useState(false)
  const [hasSent, setHasSent] = useState(false)
  const [didFail, setDidFail] = useState(false)

  const fetchRecaptchaHeaders = useRecaptchaHeaders(formRecaptchaName)

  async function sendForm(form: HTMLFormElement, formValues: FormValues) {
    if (isSending) {
      return
    }

    setDidFail(false)
    setIsSending(true)

    const formData = new FormData(form)

    if (formValues.attachment_1) {
      // Since the value could have been drag & dropped, we don't know if the input element had the value.
      // To be sure we always remove and re-attach our stored file.
      formData.delete('attachment_1')
      // By using this name we have an easier time parsing it on the server.
      formData.append('FileUpload_FormFile', formValues.attachment_1)
    }

    if (formValues.attachment_2) {
      formData.delete('attachment_2')
      formData.append('FileUpload_FormFile', formValues.attachment_2)
    }

    formData.append('sourceUrl', document.URL)
    formData.append('projectKey', options.projectKey ?? defaultProjectKey)
    formData.append('issueSummary', options.issueSummary ?? defaultIssueSummary)
    formData.append('labels', JSON.stringify([siteUrl]))

    if (options.epicId) {
      formData.append('epicId', options.epicId)
    }

    if (options.assignee) {
      formData.append('assignee', options.assignee)
    }

    try {
      const recaptchaHeaders = await fetchRecaptchaHeaders()

      if (!recaptchaHeaders) {
        // Handle recaptcha failure
      }

      const response = await fetch(
        apiUrl +
          `?code=${apiKey}&recaptcha-response=${recaptchaHeaders?.['recaptcha-response']}&recaptcha-action=${recaptchaHeaders?.['recaptcha-action']}`,
        {
          method: 'POST',
          body: formData,
        }
      )

      if (response.status < 200 || response.status >= 300) {
        throw new Error()
      }

      setHasSent(true)
    } catch (error) {
      setDidFail(true)
    }

    setIsSending(false)
  }

  // Can be used for testing
  // async function sendForm() {
  //   if (isSending) {
  //     return
  //   }

  //   setIsSending(true)

  //   await new Promise((resolve) => setTimeout(resolve, 2000))

  //   setHasSent(true)
  //   // setDidFail(true)
  //   setIsSending(false)
  // }

  return { isSending, didFail, hasSent, sendForm }
}
