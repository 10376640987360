import { RefObject, useLayoutEffect } from 'react'

import useOnMount from './useOnMount'
import useWindowSize from './useWindowSize'
import useForceRender from './utils/useForceRender'
import { useDoubleRaf } from './utils/useRequestAnimationFrame'
import useTimeout from './utils/useTimeout'

export default function useAnimateElementHeight(
  elementRef: RefObject<HTMLElement | null>,
  durationMs = 400,
  easing = 'ease-out'
) {
  useWindowSize()

  const doubleRaf = useDoubleRaf()
  const _setTimeout = useTimeout()
  const forceRender = useForceRender()

  useOnMount(() => {
    _setTimeout(forceRender, 200)
  })

  useLayoutEffect(() => {
    const element = elementRef.current
    if (!element) {
      return
    }

    const { style } = element

    const currentHeight = style.height

    style.height = ''
    style.transition = ''

    const newHeight = element.clientHeight

    style.height = currentHeight

    doubleRaf(() => {
      style.height = `${newHeight}px`
      style.transition = `${durationMs}ms ${easing}`
    })
  })
}
