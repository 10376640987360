import { useState, useEffect, useRef } from 'react'

const IntersectionObserver =
  typeof window === 'undefined' || !window.IntersectionObserver
    ? null
    : window.IntersectionObserver

export default function useIsInViewport<T extends Element = HTMLElement>(
  threshold: number | number[] = 0.25,
  intersectionMargin = 0
) {
  const elementRef = useRef<T | null>(null)

  const [isInViewport, setIsInViewport] = useState(false)

  useEffect(() => {
    if (!IntersectionObserver) {
      setIsInViewport(true)
      return
    }

    const element = elementRef.current
    if (!element) {
      return
    }

    function onIntersectionUpdate(entries: IntersectionObserverEntry[]) {
      const [entry] = entries
      setIsInViewport(entry.isIntersecting)
    }

    const observerver = new IntersectionObserver(onIntersectionUpdate, {
      rootMargin: `${intersectionMargin}px 0px`,
      threshold,
    })

    observerver.observe(element)

    return () => {
      observerver.disconnect()
    }
  }, [elementRef.current, intersectionMargin, threshold])

  return { elementRef, isInViewport }
}
