let Variables = {
  formWarm: false,
  test: 0,
}
declare global {
  interface Window {
    analytics: any
    dataLayer?: any[]
  }
}

export default Variables
