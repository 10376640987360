import { clientQueryKey } from '../../templates/OurWorkPage/OurWorkPage'
import useGlobalStartPage from '../globalContent/useGlobalStartPage'

import usePageLink from './usePageLink'

export default function useOurWorkPageLink(selectedClientName?: string) {
  const startPage = useGlobalStartPage()
  const ourWorkPageLink = usePageLink(startPage?.ourWorkPage?.slug)

  return selectedClientName
    ? `${ourWorkPageLink}?${clientQueryKey}=${selectedClientName}`
    : ourWorkPageLink
}
