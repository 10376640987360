import classNames from 'classnames'
import React, { useState, useEffect, useRef } from 'react'

import useMicroCopy from '../../../hooks/globalContent/useMicroCopy'
import useAnimateElementHeight from '../../../hooks/useAnimateElementHeight'
import { useContactForm } from '../../../hooks/useContactForm'
import BaseForm from '../../_elements/BaseForm'
import ExternalLink from '../../_elements/ExternalLink'
import CTAButton from '../../_elements/_buttons/CTAButton'
import CheckboxField from '../../_elements/_inputs/CheckboxField'
import InputField from '../../_elements/_inputs/InputField'
import ActionString from '../../_functional/ActionString'
import LayoutContainer from '../../_layouts/LayoutContainer'
import SvgArrowDown from '../../_svg/SvgArrowDown'
import SvgDownload from '../../_svg/SvgDownload'

import styles from './download-form.module.scss'

interface FilesList {
  title: string
  file: { url: string; fileName: string }
}

export interface DownloadFormLayoutProps {
  additionalFilesTitle?: string
  additionalFilesList?: FilesList[]
  filesTitle: string
  filesList: FilesList[]
  formId: string
  formTitle: string
  downloadButtonText: string
  displayDownloadForm: boolean
  setDisplayDownloadForm: (value: boolean) => void
}

export default function DownloadForm({
  additionalFilesTitle,
  additionalFilesList,
  filesTitle,
  filesList,
  formId,
  formTitle,
  downloadButtonText,
  displayDownloadForm,
  setDisplayDownloadForm,
}: DownloadFormLayoutProps) {
  // As of now the user can only download one PDF file
  // Therefore only one title will be displayed
  const filesListItemTitle = filesList && filesList[0]?.title
  // URL and title of the file that will be downloaded when user submits the form
  const downloadFileUrl = filesList && filesList[0]?.file.url
  const downloadFileTitle =
    (filesList && filesList[0]?.title + '.pdf') || 'download.pdf'

  const nameField = useMicroCopy('form.name')
  const emailField = useMicroCopy('form.email')
  const gdprField = useMicroCopy('form.gdpr')
  const recaptchaDisclaimer = useMicroCopy('form.recaptcha_disclaimer')
  const errorMessage = useMicroCopy('form.error_message')
  const downloadCompleteMessage = useMicroCopy('text.downloadComplete')

  const [hasGivenConsent, setHasGivenConsent] = useState<boolean>(false)
  const [downloadComplete, setDownloadComplete] = useState<boolean>(false)

  const {
    values,
    isLoading,
    hasSentSuccessfully,
    requestError,
    setValue,
    handleSubmit,
  } = useContactForm()

  function handleCollapseButtonClick() {
    setDisplayDownloadForm(false)
  }

  function handleCheckboxFieldChange() {
    setHasGivenConsent(!hasGivenConsent)
  }

  function downloadFile(path: string, filename: string) {
    // Create a new link
    const anchor = document.createElement('a')
    anchor.href = 'https:' + path
    console.log('PATH', anchor.href)

    //disable download attr so that pdf shows in browser instead of downloading
    // anchor.download = filename
    anchor.target = '_blank'


    // Append to the DOM
    document.body.appendChild(anchor)

    // Trigger `click` event
    anchor.click()

    // Remove element from DOM
    document.body.removeChild(anchor)
  }

  function handleFileDownload(fileUrl: string, fileTitle: string) {
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        // const url = URL.createObjectURL(new Blob([blob]))
        const url = fileUrl
        downloadFile(url, fileTitle)
        setDownloadComplete(true)
      })
  }

  useEffect(() => {
    if (hasSentSuccessfully) {
      handleFileDownload(downloadFileUrl, downloadFileTitle)

      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'download_form_submission',
        })
      }
    }
  }, [hasSentSuccessfully])

  const containerElRef = useRef<HTMLElement>(null)
  useAnimateElementHeight(containerElRef)

  return (
    <section
      className={classNames(
        styles.downloadForm,
        displayDownloadForm && styles.displayForm
      )}
      ref={containerElRef}
    >
      <LayoutContainer className={styles.container}>
        <div className={styles.filesContainer}>
          <h2 className={styles.filesTitle}>{filesTitle}</h2>
          <ul className={styles.filesList}>
            <li className={styles.filesListItem}>{filesListItemTitle}</li>
          </ul>

          {additionalFilesTitle && (
            <h2 className={styles.downloadsTitle}>{additionalFilesTitle}</h2>
          )}
          {additionalFilesList && (
            <ul className={styles.downloadsList}>
              {additionalFilesList.map((item, index) => {
                const key = index
                const title = item.title
                const id = item.file.fileName + index
                return (
                  <li key={key}>
                    <CheckboxField id={id} label={title} />
                  </li>
                )
              })}
            </ul>
          )}
        </div>

        {!hasSentSuccessfully && (
          <BaseForm
            className={styles.formContainer}
            onSubmit={(e) => handleSubmit(e, formId)}
          >
            <h2 className={styles.formTitle}>{formTitle}</h2>

            <InputField
              label={nameField}
              id="downloadFormName"
              name="name"
              value={values.name}
              onChange={setValue}
            />
            <InputField
              type="email"
              label={emailField}
              id="downloadFormMail"
              name="mail"
              value={values.mail}
              onChange={setValue}
            />
            <CheckboxField
              id="gdprConsent"
              label={
                <ActionString value={gdprField}>
                  {(text, link) => (
                    <ExternalLink
                      key={link}
                      to={link!}
                      segmentdata={{
                        anchor_text: text,
                        position: 'body',
                        url: link,
                      }}
                    >
                      {text}
                    </ExternalLink>
                  )}
                </ActionString>
              }
              onChange={handleCheckboxFieldChange}
            />

            <div className={styles.downloadButtonWrapper}>
              <small className={styles.recaptchaInfo}>
                <ActionString value={recaptchaDisclaimer}>
                  {(text, link) => (
                    <ExternalLink
                      key={link}
                      to={link!}
                      segmentdata={{
                        anchor_text: text,
                        position: 'body',
                        url: link,
                      }}
                    >
                      {text}
                    </ExternalLink>
                  )}
                </ActionString>
              </small>
              <CTAButton
                className={styles.downloadButton}
                isSmaller
                disabled={!hasGivenConsent || isLoading}
              >
                {downloadButtonText}
                <SvgDownload className={styles.downloadButtonSvg} />
              </CTAButton>
            </div>

            {requestError && !isLoading && (
              <div
                className={styles.errorMessage}
                dangerouslySetInnerHTML={{ __html: errorMessage }}
              />
            )}
          </BaseForm>
        )}

        {hasSentSuccessfully && downloadComplete && (
          <div
            className={styles.successMessage}
            dangerouslySetInnerHTML={{ __html: downloadCompleteMessage }}
          />
        )}
      </LayoutContainer>

      <button
        className={styles.collapseButton}
        onClick={handleCollapseButtonClick}
      >
        <SvgArrowDown className={styles.collapseButtonSvg} />
      </button>
    </section>
  )
}
