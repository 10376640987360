import classNames from 'classnames'
import Img from 'gatsby-image'
import React, { useEffect, useRef, useState } from 'react'

import { ContentfulAsset } from '../../../@import-types/contentful/Asset.types'
import Colors from '../../../constants/Colors'
import useAnimationLoop from '../../../hooks/useAnimationLoop'
import useElementBounds from '../../../hooks/useElementBounds'
import useIsDesktop from '../../../hooks/useIsDesktop'
import useIsInViewport from '../../../hooks/useIsInViewport'
import Blob from '../../../utils/blob-classes/Blob'
import SpringPoint from '../../../utils/blob-classes/SpringPoint'
import {
  getColorByName,
  getComplementaryColor,
  isDarkHexColor,
} from '../../../utils/colorUtils'

import styles from './video.module.scss'

export interface VideoData {
  id: string
  video: ContentfulAsset | null
  title?: string
  topic?: string | null
  topicSubtitle?: string | null
  posterImage?: ContentfulAsset | null
  color?: string | null
}
export interface VideoProps {
  video: VideoData
}

export default function Video({ video }: VideoProps) {
  const [isActive, setIsActive] = useState(false)
  const videoElRef = useRef<HTMLVideoElement>(null)
  const svgRef = useRef<SVGSVGElement>(null)
  const pathRef = useRef<SVGPathElement>(null)
  const iconGroupRef = useRef<SVGGElement>(null)

  const isDesktop = useIsDesktop(false)

  const { elementRef: rootElementRef, isInViewport } =
    useIsInViewport<HTMLDivElement>()

  const { elementRef: containerElRef, elementBoundsRef } =
    useElementBounds<HTMLDivElement>(false)

  const [blob] = useState(
    () =>
      new Blob({
        radius: 130,
        disappearRadius: 80,
        pointCount: 10,
        pointRadiusOffset: 0.15,
        pointSpeedOffset: 0.002,
      })
  )

  const [position] = useState(() => new SpringPoint(100, 8))

  const { start, stop } = useAnimationLoop((time) => {
    const pathEl = pathRef.current
    if (!pathEl) {
      return
    }

    blob.update(time)
    position.update(time.elapsedS)

    pathEl.setAttribute('d', blob.toSvgPathD())
    pathEl.setAttribute('transform', `translate(${position.x} ${position.y})`)
    iconGroupRef.current?.setAttribute(
      'transform',
      `translate(${position.x} ${position.y})`
    )
  })

  useEffect(()=>{
    setIsActive(false)
  }, [video])

  useEffect(() => {
    if (isInViewport) {
      centerBlob(true)
      start()
    } else {
      stop()
    }
  }, [isInViewport])

  useEffect(() => {
    svgRef.current?.setAttribute(
      'viewBox',
      `0 0 ${elementBoundsRef.current.domRect?.width} ${elementBoundsRef.current.domRect?.height}`
    )
  }, [elementBoundsRef.current.domRect])

  const playBackgroundColor = getColorByName(video.color)
  const playIconColor = isDarkHexColor(playBackgroundColor)
    ? Colors.White
    : getComplementaryColor(playBackgroundColor)

  function centerBlob(instant = false) {
    position.targetX =
      elementBoundsRef.current.domRect!.width * (isDesktop ? 0.5 : 0.75)
    position.targetY = elementBoundsRef.current.domRect!.height * 0.5

    if (instant) {
      position.x = position.targetX
      position.y = position.targetY
    }
  }

  function onPlayClick() {
    if (isActive === true) {
      setIsActive(false)
      stop()
      return
    }
    else if (isActive === false) {
      setIsActive(true)
      videoElRef.current?.play()
      return
    }
  }

  function onMouseEnter(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    position.updateTargetFromMouseEvent(event)
    blob.appear()
  }

  function onMouseLeave() {
    blob.disappear()
    centerBlob()
  }

  function onMouseMove(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    position.updateTargetFromMouseEvent(event)
  }
  return (
    <div
      className={classNames(styles.container, isActive && styles.isActive)}
      ref={rootElementRef}
      onClick={onPlayClick}
    >
      <div
        className={classNames(
          styles.innerContainer,
          isActive && styles.videoOnTop
        )}
        ref={containerElRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
      >
        <div className={styles.textsContainer}>
          {video.title && <h3 className={styles.videoTitle}>{video.title}</h3>}
          {video.topic && <p className={styles.topic}>{video.topic}</p>}
          {video.topicSubtitle && (
            <p className={styles.topicSubtitle}>{video.topicSubtitle}</p>
          )}
        </div>

        {video.posterImage && video.posterImage.fluid && (
          <Img
            className={styles.image}
            fluid={video.posterImage.fluid}
            alt={video.posterImage.description || video.posterImage.title || ''}
          />
        )}

        {video.video && (
          <video
            className={styles.video}
            src={video.video.file?.url}
            ref={videoElRef}
            controls
          />
        )}

        <svg className={styles.svg} ref={svgRef}>
          <path
            ref={pathRef}
            fill={playBackgroundColor}
            style={{ opacity: 0.8 }}
            d={blob.toSvgPathD()}
          />
          <g ref={iconGroupRef}>
            <path
              d="M17.5996 10.4999L0.949609 20.1128L0.94961 0.887019L17.5996 10.4999Z"
              fill={playIconColor}
              transform="translate(-9 -10.5)"
            />
          </g>
        </svg>
      </div>
    </div>
  )
}
