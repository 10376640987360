import React, { ReactNode } from 'react'

import styles from './document-teaser.module.scss'

interface DocumentTeaserProps {
  imageColumn: ReactNode | ReactNode[]
  textColumn: ReactNode | ReactNode[]
}

export default function DocumentTeaser({
  imageColumn,
  textColumn,

  ...divProps
}: DocumentTeaserProps & JSX.IntrinsicElements['div']) {
  return (
    <div className={styles.container} {...divProps}>
      <div className={styles.imageColumn}>{imageColumn}</div>
      <div className={styles.textColumn}>{textColumn}</div>
    </div>
  )
}
