import { graphql } from 'gatsby'
import React from 'react'

import ContentfulRichText from '../../_elements/ContentfulRichText'
import LayoutContainer from '../../_layouts/LayoutContainer'
import Heading3 from '../../_typography/Heading3/Heading3'
import { TextBlockProps } from '../TextBlock/TextBlock'

import styles from './steps-block.module.scss'

export const StepsBlockTypename = 'ContentfulBlockSteps'

export interface StepsBlockProps {
  __typename: typeof StepsBlockTypename
  title: string
  steps: TextBlockProps[]
}

export default function StepsBlock({ title, steps }: StepsBlockProps) {
  return (
    <LayoutContainer className={styles.container} withMargin>
      {/* Uses a different styling than Heading2 */}
      <h2 className={styles.title}>{title}</h2>

      <ol className={styles.list}>
        {steps?.map((step) => (
          <li key={step.title} className={styles.item}>
            <Heading3 className={styles.itemTitle}>{step.title}</Heading3>
            {step.bodyText && <ContentfulRichText json={step.bodyText.json} />}
          </li>
        ))}
      </ol>
    </LayoutContainer>
  )
}

export const stepsBlockFragment = graphql`
  fragment StepsBlockFragment on Node {
    ... on ContentfulBlockSteps {
      title
      steps {
        ...TextBlockFragment
      }
    }
  }
`
