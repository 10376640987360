import classNames from 'classnames'
import React, { useState } from 'react'

import useFormElementId from '../../../../hooks/forms/useFormElementId'
import SvgCross from '../../../_svg/SvgCross'
import BaseFormField, {
  BaseFormFieldPublicProps,
} from '../BaseFormField/BaseFormField'
import baseFormFieldStyles from '../BaseFormField/base-form-field.module.scss'

import styles from './attachment-field.module.scss'

interface AttachmentFieldProps extends BaseFormFieldPublicProps {
  value: File | null

  onChange?: (name: string, file: File | null) => void
}

export default function AttachmentField({
  label,

  id,
  name,
  value,

  isOptional,

  className,

  onChange,

  ...inputProps
}: AttachmentFieldProps &
  Omit<JSX.IntrinsicElements['input'], 'value' | 'onChange'>) {
  const renderId = useFormElementId(id, name, label)

  const [isDraggingFile, setIsDraggingFile] = useState(false)

  const selectedFileName = value?.name
  const hasFile = Boolean(selectedFileName)

  function onDragEnter(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault()
    setIsDraggingFile(true)
  }

  function onDragExit(event: React.DragEvent<HTMLDivElement>) {
    setIsDraggingFile(false)
  }

  function onDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault()

    setIsDraggingFile(false)

    const file = event.dataTransfer?.items[0].getAsFile() ?? null

    onChange?.(name, file)
  }

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.currentTarget.files?.[0] ?? null
    onChange?.(name, file)
  }

  function onRemoveClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    onChange?.(name, null)
  }

  return (
    <BaseFormField
      className={classNames(
        isDraggingFile && styles.isDraggingFile,
        isDraggingFile && baseFormFieldStyles.hasValue,
        hasFile && styles.hasFile,
        className
      )}
      id={renderId}
      name={name}
      label={label}
      value={selectedFileName}
      isOptional={isOptional}
      onDragOver={onDragEnter}
      onDragLeave={onDragExit}
      onDrop={onDrop}
    >
      <label className={styles.wrapperLabel}>
        <input
          className={styles.input}
          type="file"
          id={renderId}
          name={name}
          required={!isOptional}
          onChange={onInputChange}
          {...inputProps}
        />

        {hasFile && (
          <>
            <span className={styles.fileName}>{selectedFileName}</span>

            <button className={styles.removeButton} onClick={onRemoveClick}>
              <SvgCross className={styles.removeIcon} />
            </button>
          </>
        )}
      </label>
    </BaseFormField>
  )
}
