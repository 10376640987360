import { graphql } from 'gatsby'
import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import ApplyToJobFormBlock, {
  ApplyToJobFormBlockProps,
  ApplyToJobBlockTypename,
} from '../_blocks/ApplyToJobFormBlock/ApplyToJobFormBlock'
import ClientsListingBlock, {
  ClientsListingBlockProps,
  ClientsListingBlockTypename,
} from '../_blocks/ClientsListingBlock/ClientsListingBlock'
import DividerBlock, {
  DividerBlockProps,
  DividerBlockTypename,
} from '../_blocks/DividerBlock/DividerBlock'
import DocumentTeaserBlock, {
  DocumentTeaserBlockProps,
  DocumentTeaserBlockTypename,
} from '../_blocks/DocumentTeaserBlock/DocumentTeaserBlock'
import EmployeeGridBlock, {
  EmployeeGridBlockProps,
  EmployeeGridBlockTypename,
} from '../_blocks/EmployeeGridBlock/EmployeeGridBlock'
import GalleryBlock, {
  GalleryBlockProps,
  GalleryBlockTypename,
} from '../_blocks/GalleryBlock/GalleryBlock'
import HubspotDocumentTeaserBlock, {
  HubspotDocumentTeaserBlockProps,
  HubspotDocumentTeaserBlockTypename,
} from '../_blocks/HubspotDocumentTeaserBlock/HubspotDocumentTeaserBlock'
import HubspotFormBlock, {
  HubspotFormBlockTypename,
  HubspotFormBlockProps,
} from '../_blocks/HubspotFormBlock/HubspotFormBlock'
import ImageBlock, {
  ImageBlockProps,
  ImageBlockTypename,
} from '../_blocks/ImageBlock/ImageBlock'
import JIRAIssueCollectorBlock, {
  JIRAIssueCollectorBlockProps,
  JIRAIssueCollectorBlockTypename,
} from '../_blocks/JIRAIssueCollectorBlock/JIRAIssueCollectorBlock'
import JobTeasersGridBlock, {
  JobTeasersGridBlockProps,
  JobTeasersGridBlockTypename,
} from '../_blocks/JobTeasersGridBlock/JobTeasersGridBlock'
import PartnersBlock, {
  PartnersBlockTypename,
  PartnersBlockProps,
} from '../_blocks/PartnersBlock/PartnersBlock'
import QuoteBlock, {
  QuoteBlockProps,
  QuoteBlockTypename,
} from '../_blocks/QuoteBlock/QuoteBlock'
import ReferenceTilesBlock, {
  ReferenceTilesBlockProps,
  ReferenceTilesBlockTypename,
} from '../_blocks/ReferenceTilesBlock/ReferenceTilesBlock'
import SignUpFormBlock, {
  SignUpFormBlockTypename,
  SignUpFormBlockProps,
} from '../_blocks/SignUpFormBlock/SignUpFormBlock'
import StatisticsBlock, {
  StatisticsBlockProps,
  StatisticsBlockTypename,
} from '../_blocks/StatisticsBlock/StatisticsBlock'
import StepsBlock, {
  StepsBlockTypename,
  StepsBlockProps,
} from '../_blocks/StepsBlock/StepsBlock'
import TeaserCarouselBlock, {
  TeaserCarouselBlockProps,
  TeaserCarouselBlockTypename,
} from '../_blocks/TeaserCarouselBlock/TeaserCarouselBlock'
import TextBlock from '../_blocks/TextBlock'
import {
  TextBlockProps,
  TextBlockTypename,
} from '../_blocks/TextBlock/TextBlock'
import TilesBlock, {
  TilesBlockProps,
  TilesBlockTypename,
} from '../_blocks/TilesBlock/TilesBlock'
import VideoBlock, {
  VideoBlockProps,
  VideoBlockTypename,
} from '../_blocks/VideoBlock/VideoBlock'
import VideoCollectionBlock, {
  VideoCollectionBlockProps,
  VideoCollectionBlockTypename,
} from '../_blocks/VideoCollectionBlock/VideoCollectionBlock'
export type AllContentAreaTypes =
  | ApplyToJobFormBlockProps
  | ClientsListingBlockProps
  | DividerBlockProps
  | DocumentTeaserBlockProps
  | HubspotDocumentTeaserBlockProps
  | EmployeeGridBlockProps
  | GalleryBlockProps
  | ImageBlockProps
  | JIRAIssueCollectorBlockProps
  | JobTeasersGridBlockProps
  | PartnersBlockProps
  | QuoteBlockProps
  | SignUpFormBlockProps
  | HubspotFormBlockProps
  | StatisticsBlockProps
  | StepsBlockProps
  | TeaserCarouselBlockProps
  | TextBlockProps
  | TilesBlockProps
  | VideoBlockProps
  | ReferenceTilesBlockProps
  | VideoCollectionBlockProps

interface ContentAreaProps {
  content: AllContentAreaTypes[]
}
export default function ContentArea({ content }: ContentAreaProps) {
  return (
    <>
      {content.map((item, index) => {
        const key = index

        switch (item.__typename) {
          case ApplyToJobBlockTypename:
            return (
              <GoogleReCaptchaProvider
                key={key}
                reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
              >
                <ApplyToJobFormBlock {...item} />
              </GoogleReCaptchaProvider>
            )

          case ClientsListingBlockTypename:
            return <ClientsListingBlock key={key} {...item} />

          case DividerBlockTypename:
            return <DividerBlock key={key} {...item} />

          case DocumentTeaserBlockTypename:
            return (
              <GoogleReCaptchaProvider
                key={key}
                reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
              >
                <DocumentTeaserBlock {...item} />
              </GoogleReCaptchaProvider>
            )
          case HubspotDocumentTeaserBlockTypename:
            return <HubspotDocumentTeaserBlock key={key} {...item} />

          case EmployeeGridBlockTypename:
            return <EmployeeGridBlock key={key} {...item} />

          case GalleryBlockTypename:
            return <GalleryBlock key={key} {...item} />

          case ImageBlockTypename:
            return <ImageBlock key={key} {...item} />

          case JIRAIssueCollectorBlockTypename:
            return <JIRAIssueCollectorBlock key={key} {...item} />

          case JobTeasersGridBlockTypename:
            return <JobTeasersGridBlock key={key} {...item} />

          case PartnersBlockTypename:
            return <PartnersBlock key={key} {...item} />

          case QuoteBlockTypename:
            return <QuoteBlock key={key} {...item} />

          case SignUpFormBlockTypename:
            return (
              <GoogleReCaptchaProvider
                key={key}
                reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
              >
                <SignUpFormBlock key={key} {...item} />
              </GoogleReCaptchaProvider>
            )

          case HubspotFormBlockTypename:
            return (
              <GoogleReCaptchaProvider
                key={key}
                reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
              >
                <HubspotFormBlock key={key} {...item} />
              </GoogleReCaptchaProvider>
            )

          case StatisticsBlockTypename:
            return <StatisticsBlock key={key} {...item} />

          case StepsBlockTypename:
            return <StepsBlock key={key} {...item} />

          case TeaserCarouselBlockTypename:
            return <TeaserCarouselBlock key={key} {...item} />

          case TextBlockTypename:
            return <TextBlock key={key} {...item} />

          case TilesBlockTypename:
            return <TilesBlock key={key} {...item} />

          case VideoBlockTypename:
            return <VideoBlock key={key} {...item} />

          case ReferenceTilesBlockTypename:
            return <ReferenceTilesBlock key={key} {...item} />

          case VideoCollectionBlockTypename:
            return <VideoCollectionBlock key={key} {...item} />

          default:
            return null
        }
      })}
    </>
  )
}

export const contentAreaFragment = graphql`
  fragment ContentAreaFragment on Node {
    ...ApplyToJobFormBlockFragment

    ...ClientsListingBlockFragment

    ...DividerBlockFragment

    ...DocumentTeaserBlockFragment

    ...HubspotDocumentTeaserBlockFragment

    ...EmployeeGridBlockFragment

    ...GalleryBlockFragment

    ...HubspotFormBlockFragment

    ...ImageBlockFragment

    ...JIRAIssueCollectorBlockFragment

    ...JobTeasersGridBlockFragment

    ...PartnersBlockFragment

    ...QuoteBlockFragment

    ...SignUpFormBlockFragment

    ...StatisticsBlockFragment

    ...StepsBlockFragment

    ...TeaserCarouselBlockFragment

    ...TextBlockFragment

    ...TilesBlockFragment

    ...VideoBlockFragment

    ...ReferenceTilesBlockFragment

    ...VideoCollectionBlockFragment
  }
`
