import React, { ReactNode } from 'react'

import useActionString from '../../../hooks/useActionString'

interface ActionStringProps {
  value: string | undefined | null
  children: (text?: string, argument?: string) => ReactNode
}

export default function ActionString({ value, children }: ActionStringProps) {
  const actionArray = useActionString(value)

  return (
    <>
      {actionArray &&
        actionArray.map((actionArrayItem) => {
          if (typeof actionArrayItem === 'string') {
            return <span key={actionArrayItem}>{actionArrayItem}</span>
          } else {
            return children(actionArrayItem?.text, actionArrayItem?.argument)
          }
        })}
    </>
  )
}
