import { useState } from 'react'

import GlobalVariables from '../globals/GlobalVariables'

import useRecaptchaHeaders from './forms/useRecaptchaHeaders'

interface IKrusoForm {
  id: string
  companyName: string
  confirmationText: string
  confirmationSubject: string
  name: string
  mail: string
  message: string
  phone: string
  mailingConsent: string
}

const formRecaptchaName =
  process.env.GATSBY_CONTACT_FORM_RECAPTCHA_NAME ?? 'contactform'

export function useContactForm() {
  const [formValues, setFormValues] = useState<IKrusoForm>({
    id: '',
    companyName: '',
    confirmationText: '', // Confirmation email to user
    confirmationSubject: '', // Confirmation email to user
    name: '',
    mail: '',
    message: '',
    phone: '',
    mailingConsent: '',
  })

  const [isLoading, setIsLoading] = useState(false)
  const [hasSentSuccessfully, setHasSentSuccessfully] = useState(false)
  const [requestError, setRequestError] = useState<any>(null)

  const mailHost = process.env.GATSBY_MAIL_HOST
  const apiKey = process.env.GATSBY_MAIL_API_KEY
  const healthCheckApiKey = process.env.GATSBY_HEALTH_CHECK_KEY

  const fetchRecaptchaHeaders = useRecaptchaHeaders(formRecaptchaName)

  function warmupMailService() {
    if (!GlobalVariables.formWarm) {
      GlobalVariables.formWarm = true
      fetch(`${mailHost}/api/HealthCheck?code=${healthCheckApiKey}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }).then(() => {})
    }
  }

  const setValue = function (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    warmupMailService()
    setFormValues({
      ...formValues,
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  const handleSubmit = async (
    e: any,
    formId: string,
    emailConfirmationMessage?: string,
    emailConfirmationSubject?: string
  ) => {
    e.persist()
    e.preventDefault()

    setIsLoading(true)

    const recaptchaHeaders = await fetchRecaptchaHeaders()
    // track user
    // window.analytics.track(
    //   'Form Submitted',
    //   {
    //     id: formId,
    //     companyName: formValues.companyName,
    //     name: formValues.name,
    //     email: formValues.mail,
    //     message: formValues.message,
    //     phone: formValues.phone,
    //   },
    //   null,
    //   () => {
    //     console.log('Form Submitted')
    //   }
    // )
    // identify user - not sure if it makes sense calling this w/o user_id
    //   window.analytics.identify({
    //     name: formValues.name,
    //     email: formValues.mail,
    // });

    // // Check if the captcha was skipped or not
    if (!recaptchaHeaders) {
      setIsLoading(false)
      return
    }

    const requestBody = {
      ...formValues,
      id: formId,
      confirmationText: emailConfirmationMessage,
      confirmationSubject: emailConfirmationSubject,
    }

    fetch(`${mailHost}/api/SendMail?code=${apiKey}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        ...recaptchaHeaders,
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => {
        res.json()
      })
      .then((data) => {
        setHasSentSuccessfully(true)
      })
      .catch((error) => {
        console.log(error)
        setRequestError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // // Can be used for testing
  // async function handleSubmit(e: any) {
  //   e.preventDefault()

  //   setIsLoading(true)

  //   await new Promise((resolve) => setTimeout(resolve, 2000))

  //   setHasSentSuccessfully(true)
  //   // setRequestError(true)
  //   setIsLoading(false)
  // }

  return {
    values: formValues,
    isLoading,
    hasSentSuccessfully,
    requestError,
    setValue,
    handleSubmit,
  }
}
