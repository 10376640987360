import { ContentfulAsset } from './Asset.types'
import { BasePageContent } from './BasePage.types'
import { BlogPostContent } from './BlogPost.types'
import { Tag } from './general.types'

export const BlogListingPageTypename = 'ContentfulPageBlogListing'

export interface BlogListingPageType
  extends BasePageContent<typeof BlogListingPageTypename> {
  title?: string | null
  heroSubtitle?: string | null
  heroFilterPreamble?: string | null

  navigationTitle?: string | null

  teaserTitle?: string | null
  teaserDescription?: string | null
  teaserColor?: string | null

  teaserImage?: ContentfulAsset | null

  showBlogPostsWithTags?: Tag[] | null

  sortingOrder?: BlogPostContent[] | null
}
