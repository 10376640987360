import classNames from 'classnames'
import Img from 'gatsby-image'
import React, { useEffect, useRef, useState } from 'react'

import Colors from '../../../constants/Colors'
import useAnimationLoop from '../../../hooks/useAnimationLoop'
import useElementBounds from '../../../hooks/useElementBounds'
import useIsDesktop from '../../../hooks/useIsDesktop'
import useIsInViewport from '../../../hooks/useIsInViewport'
import Blob from '../../../utils/blob-classes/Blob'
import SpringPoint from '../../../utils/blob-classes/SpringPoint'
import {
  getColorByName,
  getComplementaryColor,
  isDarkHexColor,
} from '../../../utils/colorUtils'
import { VideoData } from '../../_elements/Video'

import styles from './video-card.module.scss'
export interface VideoProps {
  video: VideoData
}

export default function Video({ video }: VideoProps) {
  const isActive: boolean = false
  const videoElRef = useRef<HTMLVideoElement>(null)
  const svgRef = useRef<SVGSVGElement>(null)
  const pathRef = useRef<SVGPathElement>(null)
  const iconGroupRef = useRef<SVGGElement>(null)

  const isDesktop = useIsDesktop(false)

  const { elementRef: rootElementRef, isInViewport } =
    useIsInViewport<HTMLDivElement>()

  const { elementRef: containerElRef, elementBoundsRef } =
    useElementBounds<HTMLDivElement>(false)

  const [blob] = useState(
    () =>
      new Blob({
        radius: 80,
        disappearRadius: 40,
        pointCount: 10,
        pointRadiusOffset: 0.15,
        pointSpeedOffset: 0.002,
      })
  )

  const [position] = useState(() => new SpringPoint(100, 8))

  const { start, stop } = useAnimationLoop((time) => {
    const pathEl = pathRef.current
    if (!pathEl) {
      return
    }

    blob.update(time)
    position.update(time.elapsedS)

    pathEl.setAttribute('d', blob.toSvgPathD())
    pathEl.setAttribute('transform', `translate(${position.x} ${position.y})`)
    iconGroupRef.current?.setAttribute(
      'transform',
      `translate(${position.x} ${position.y})`
    )
  })

  useEffect(() => {
    if (isInViewport) {
      centerBlob()
      start()
    } else {
      stop()
    }
  }, [isInViewport])

  useEffect(() => {
    svgRef.current?.setAttribute(
      'viewBox',
      `0 0 ${elementBoundsRef.current.domRect?.width} ${elementBoundsRef.current.domRect?.height}`
    )
  }, [elementBoundsRef.current.domRect])

  const playBackgroundColor = getColorByName(video.color)
  const playIconColor = isDarkHexColor(playBackgroundColor)
    ? Colors.White
    : getComplementaryColor(playBackgroundColor)

  function centerBlob() {
    position.targetX =
      elementBoundsRef.current.domRect!.width * (isDesktop ? 0.5 : 0.75)
    position.targetY = elementBoundsRef.current.domRect!.height * 0.5

    position.x = position.targetX
    position.y = position.targetY
  }

  function onMouseEnter(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    position.updateTargetFromMouseEvent(event)
    blob.appear()
  }

  function onMouseLeave() {
    blob.disappear()
    centerBlob()
  }

  function onMouseMove(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    position.updateTargetFromMouseEvent(event)
  }
  return (
    <div className={styles.container}>
      <div
        className={classNames(
          styles.videoContainer,
          isActive && styles.isActive
        )}
        ref={rootElementRef}
      >
        <div
          className={classNames(styles.innerVideoContainer)}
          ref={containerElRef}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseMove={onMouseMove}
        >
          {video.posterImage && video.posterImage.fluid && (
            <Img
              className={styles.image}
              fluid={video.posterImage.fluid}
              alt={
                video.posterImage.description || video.posterImage.title || ''
              }
            />
          )}

          {video.video && (
            <video
              className={styles.video}
              src={video.video.file?.url}
              ref={videoElRef}
            />
          )}

          <svg className={styles.svg} ref={svgRef}>
            <path
              ref={pathRef}
              fill={playBackgroundColor}
              style={{ opacity: 0.8 }}
              d={blob.toSvgPathD()}
            />
            <g ref={iconGroupRef}>
              <path
                d="M17.5996 10.4999L0.949609 20.1128L0.94961 0.887019L17.5996 10.4999Z"
                fill={playIconColor}
                transform="translate(-9 -10.5)"
              />
            </g>
          </svg>
        </div>
      </div>
      <p className={styles.videoTitle}>{video.title}</p>
    </div>
  )
}
