import { graphql } from 'gatsby'
import React from 'react'

import { StatisticElement } from '../../../@import-types/contentful/StatisticElement.types'
import ContentfulRichText from '../../_elements/ContentfulRichText'
import AnimatedNumber from '../../_functional/AnimatedNumber'
import LayoutContainer from '../../_layouts/LayoutContainer'
import RichTextLayout from '../../_layouts/RichTextLayout'
import SideColumnLayout from '../../_layouts/SideColumnLayout'

import styles from './statistics-block.module.scss'

export const StatisticsBlockTypename = 'ContentfulBlockStatistics'

export interface StatisticsBlockProps {
  __typename: typeof StatisticsBlockTypename

  statisticElements: StatisticElement[] | null | undefined

  bodyText:
    | {
        json: any
      }
    | null
    | undefined
}

export default function StatisticsBlock({
  statisticElements,

  bodyText,
}: StatisticsBlockProps) {
  return (
    <LayoutContainer withMargin>
      <SideColumnLayout
        sideColumn={
          statisticElements && (
            <ul className={styles.statisticsList}>
              {statisticElements.map((element, index) => (
                <li key={element.id!} className={styles.statisticElement}>
                  <div className={styles.statisticTitle}>{element.title}</div>
                  <div className={styles.statisticValue}>
                    <AnimatedNumber
                      value={element.value}
                      springOptions={{ stiffness: 150 }}
                    />
                  </div>
                </li>
              ))}
            </ul>
          )
        }
        mainColumn={
          <RichTextLayout>
            {bodyText && <ContentfulRichText json={bodyText.json} />}
          </RichTextLayout>
        }
      />
    </LayoutContainer>
  )
}

export const textBlockFragment = graphql`
  fragment StatisticsBlockFragment on Node {
    ... on ContentfulBlockStatistics {
      __typename
      id

      statisticElements {
        id
        title
        value
      }

      bodyText {
        json
      }
    }
  }
`
