import { graphql } from 'gatsby'
import React from 'react'

import { JobRolePageContent } from '../../../@import-types/contentful/JobRolePage.types'
import JobTeasersGrid from '../../_layouts/JobTeasersGrid'

export const JobTeasersGridBlockTypename = 'ContentfulBlockJobTeasersGrid'

export interface JobTeasersGridBlockProps {
  __typename: typeof JobTeasersGridBlockTypename

  title?: string | null

  jobs: JobRolePageContent[] | null | undefined
}

export default function JobTeasersGridBlock({
  title,

  jobs,
}: JobTeasersGridBlockProps) {
  if (!jobs) {
    return null
  }

  return <JobTeasersGrid title={title} jobs={jobs} />
}

export const jobTeasersGridBlockFragment = graphql`
  fragment JobTeasersGridBlockFragment on Node {
    ... on ContentfulBlockJobTeasersGrid {
      title

      jobs {
        ...JobRolePageTeaserFragment
      }
    }
  }
`
