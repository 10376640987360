import React, { useEffect, useRef, useState } from 'react'

import useAnimationLoop from '../../../hooks/useAnimationLoop'
import useIsInViewport from '../../../hooks/useIsInViewport'
import useTimeout from '../../../hooks/utils/useTimeout'
import { Spring } from '../../../utils/animation/spring'
import SpringValue from '../../../utils/blob-classes/SpringValue'

interface AnimatedNumberProps {
  value: string | number | null | undefined

  delay?: number

  springOptions?: Partial<Spring>
}

export default function AnimatedNumber({
  value,

  delay = 0,

  springOptions,
}: AnimatedNumberProps) {
  const [spring] = useState(
    () =>
      new SpringValue(
        springOptions?.stiffness,
        springOptions?.dampness,
        springOptions?.dampness
      )
  )

  const _setTimeout = useTimeout()

  const { elementRef, isInViewport } = useIsInViewport()
  const isInViewportRef = useRef(isInViewport)
  isInViewportRef.current = isInViewport

  const { start, stop } = useAnimationLoop((time) => {
    spring.update(time.elapsedS)

    if (spring.isStale) {
      stop()
    }

    renderSpringValue()
  })

  useEffect(() => {
    if (isInViewport) {
      start()
    }
  }, [isInViewport])

  useEffect(() => {
    _setTimeout(() => {
      let parsedValue = 0
      if (typeof value === 'string') {
        parsedValue = parseInt(value, 10)
      } else if (typeof value === 'number' && !isNaN(value)) {
        parsedValue = value
      }

      spring.targetValue = parsedValue

      if (isInViewportRef.current) {
        start()
      }
    }, delay)
  }, [value])

  useEffect(renderSpringValue)

  function renderSpringValue() {
    const element = elementRef.current
    if (element) {
      element.innerHTML = spring.value.toFixed(0)
    }
  }

  return <span ref={elementRef}>{value}</span>
}
