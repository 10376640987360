import { ContentfulAsset } from './Asset.types'
import { BasePageContent } from './BasePage.types'
import { TaggedContent } from './BaseTagedContent'
import { ContentfulEmployee } from './Employee.types'
import { Tag } from './general.types'

export const BlogPostTypename = 'ContentfulBlogPost'

export interface BlogPostContent
  extends BasePageContent<typeof BlogPostTypename>,
    TaggedContent {
  title?: string | null
  titleLabel?: string | null | undefined
  slug?: string | null

  shortDescription?: string | null

  publishDate?: string | null
  authors?: ContentfulEmployee[] | null

  heroBackgroundColor?: string | null

  teaserTitle?: string | null
  teaserDescription?: string | null
  teaserColor?: string | null

  introduction?: {
    json: any
  } | null

  content?: any[] | null

  tags?: Tag[]

  heroImage?: ContentfulAsset | null
  teaserImage?: ContentfulAsset | null
}
