import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React, { useState } from 'react'

import { ContentfulAsset } from '../../../@import-types/contentful/Asset.types'
//import { DocumentTeaserTypes } from 'types'
import useIsDesktop from '../../../hooks/useIsDesktop'
import createAnchorId from '../../../utils/createAnchorId'
import DocumentTeaser from '../../_layouts/DocumentTeaser'
import DocumentTeaserText from '../../_layouts/DocumentTeaser/DocumentTeaserText'
import DownloadForm from '../../_layouts/DownloadForm/DownloadForm'
import HubspotDocumentDownload from '../../_layouts/HubspotDocumentDownload/HubspotDocumentDownload'
import LayoutContainer from '../../_layouts/LayoutContainer'
import Heading2 from '../../_typography/Heading2'

import styles from './document-teaser-block.module.scss'

export const HubspotDocumentTeaserBlockTypename = 'ContentfulBlockHubspotDocumentTeaser'

export interface HubspotDocumentTeaserBlockProps {
  __typename: typeof HubspotDocumentTeaserBlockTypename
  title: string
  bodyText: {
    json: any
  }
  image?: ContentfulAsset | null

  filesTitle: string
  filenameList: string[]
  formTitle: string
  downloadFormId: string
  slug: string
}

export default function HubspotDocumentTeaserBlock({
  title,
  bodyText,
  image,
  filesTitle,
  filenameList,
  formTitle,
  downloadFormId,
  slug,
}: HubspotDocumentTeaserBlockProps) {
  const hasImage = Boolean(image && image.fluid)
  const isDesktop = useIsDesktop()
  const cls = (...classes: any) => classes.filter(Boolean).join(' ')
  const [displayDownloadForm, setDisplayDownloadForm] = useState<boolean>(false)
  const formId = downloadFormId

  return (
    <div className={styles.blockContainer}>
      <div id={slug}
        className={cls(
          styles.teaserContainer,
          displayDownloadForm && styles.teaserAnimation
        )}
      >
        <LayoutContainer>
          <DocumentTeaser
            imageColumn={
              <>
                {!isDesktop && (
                  <Heading2
                    className={styles.isMobileHeading}
                    id={createAnchorId(title)}
                  >
                    {title}
                  </Heading2>
                )}
                {hasImage && (
                  <Img
                    className={styles.image}
                    fluid={image!.fluid}
                    alt={image!.description || image!.title || ''}
                  />
                )}
              </>
            }
            textColumn={
              <DocumentTeaserText
                title={title}
                bodyText={bodyText}
                setDisplayDownloadForm={setDisplayDownloadForm}
                displayDownloadForm={displayDownloadForm}
              />
            }
          />
        </LayoutContainer>
      </div>

      <HubspotDocumentDownload
        filesTitle={filesTitle}
        filenameList={filenameList}
        formId={formId}
        formTitle={formTitle}
        displayDownloadForm={displayDownloadForm}
        formTarget={slug+"-form"}
        setDisplayDownloadForm={setDisplayDownloadForm}
      />
    </div>
  )
}

export const HubspotDocumentTeaserBlockFragment = graphql`
  fragment HubspotDocumentTeaserBlockFragment on Node {
    ... on ContentfulBlockHubspotDocumentTeaser {
      id
      title
      image {
        title
        description
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      bodyText {
        json
      }
      slug
      downloadFormId
      filenameList
      filesTitle
      formTitle
      id
    }
  }
`
