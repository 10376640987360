import { ContentfulAsset } from './Asset.types'
import { BasePageContent } from './BasePage.types'
import { TaggedContent } from './BaseTagedContent'
import { ContentfulClient } from './Client.types'
import { ContentfulEmployee } from './Employee.types'
import { Tag } from './general.types'

export const CaseTypename = 'ContentfulCasePage'

export interface CaseContent
  extends BasePageContent<typeof CaseTypename>,
    TaggedContent {
  projectName: string
  smallerHeading?: boolean | null
  launchDate?: string | null
  projectLink?: string | null

  shortDescription?: string | null
  teaserTitle?: string | null

  introduction?: {
    json: any
  } | null

  caseColor?: string | null
  client?: ContentfulClient | null

  tags?: Tag[] | undefined

  heroImage?: ContentfulAsset | null
  teaserImage?: ContentfulAsset | null
  nextCaseTeaserImage?: ContentfulAsset | null

  content?: any[] | null

  involvedEmployees?: ContentfulEmployee[] | null
}
