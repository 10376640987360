import classNames from 'classnames'
import React from 'react'

import styles from './rich-text-layout.module.scss'

interface RichTextLayoutProps {
  smallerMargins?: boolean
}

export default function RichTextLayout({
  smallerMargins = false,

  className,

  ...divProps
}: RichTextLayoutProps & JSX.IntrinsicElements['div']) {
  return (
    <div
      className={classNames(
        styles.richText,
        smallerMargins && styles.smallerMargins,
        className
      )}
      {...divProps}
    />
  )
}
