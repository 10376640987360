import classNames from 'classnames'
import { graphql, Link } from 'gatsby'
import React, { useRef, useState } from 'react'

import { ContentfulClient } from '../../../@import-types/contentful/Client.types'
import { useFilteredEntriesByTag } from '../../../hooks/content/useFilteredEntiresByTag'
import useTagOptions from '../../../hooks/content/useTagOptions'
import useMicroCopy from '../../../hooks/globalContent/useMicroCopy'
import useOurWorkPageLink from '../../../hooks/routing/useOurWorkPageLink'
import useFadeTransitionItems from '../../../hooks/useFadeTransitionItems'
import { clientQueryKey } from '../../../templates/OurWorkPage/OurWorkPage'
import FilterDropdown from '../../_elements/FilterDropdown'
import { InternalLink } from '../../_elements/InternalLink/InternalLink'
import ActionString from '../../_functional/ActionString'
import LayoutContainer from '../../_layouts/LayoutContainer'

import styles from './clients-listing-block.module.scss'

export const ClientsListingBlockTypename = 'ContentfulBlockClientsListing'

export interface ClientsListingBlockProps {
  __typename: typeof ClientsListingBlockTypename

  filterText: string
  filterTags: string[]

  clients: ContentfulClient[]
}

export default function ClientsListingBlock({
  filterText,
  clients,
}: ClientsListingBlockProps) {
  const clientsListRef = useRef<HTMLUListElement>(null)

  const ourWorkPageLink = useOurWorkPageLink()

  const filterAllText = useMicroCopy('text.filterAllClients')
  const options = useTagOptions(clients, filterAllText)

  const [selectedFilter, setSelectedFilter] = useState(options?.[0]?.value)

  const filteredClients = useFilteredEntriesByTag(clients, selectedFilter)

  const cacheValuesBeforeChange = useFadeTransitionItems({
    containerElRef: clientsListRef,
    animateContainerHeight: true,
    durationMultiplier: 1,
    translateX: 5,
  })

  function onFilterChange(value: string) {
    cacheValuesBeforeChange()
    setSelectedFilter(value)
  }

  return (
    <LayoutContainer className={styles.container} id="clients" withMargin>
      <div className={styles.text}>
        <ActionString value={filterText}>
          {() => (
            <FilterDropdown
              key="filter"
              className={styles.filter}
              label="Choose filter"
              options={options}
              value={selectedFilter}
              onChange={onFilterChange}
            />
          )}
        </ActionString>
      </div>

      <ul className={styles.list} ref={clientsListRef}>
        {filteredClients.map((client, index) => (
          <li
            key={client.name!}
            className={classNames(
              styles.item,
              index === filteredClients.length - 1 && styles.itemIsLast
            )}
            data-name={client.name}
          >
            <InternalLink
              to={`${ourWorkPageLink}/${
                client.case_page === null ? '' : client.case_page[0].slug
              }`}
              segmentdata={{
                anchor_text: client.name,
                position: 'body',
                url: `${ourWorkPageLink}/${
                  client.case_page === null ? '' : client.case_page[0].slug
                }`,
              }}
            >
              {client.name}
            </InternalLink>
          </li>
        ))}
      </ul>
    </LayoutContainer>
  )
}

export const ClientsListingBlockFragment = graphql`
  fragment ClientsListingBlockFragment on Node {
    ... on ContentfulBlockClientsListing {
      filterText

      clients {
        name

        case_page {
          slug
        }

        tags {
          text
          value
        }
      }
    }
  }
`
