import { ContentfulEmployee } from '../@import-types/contentful/Employee.types'

export function getEmployeeFullname(employee: ContentfulEmployee): string {
  let fullName = ''

  if (employee.firstName) {
    fullName += employee.firstName
  }

  if (employee.lastName) {
    fullName += ' ' + employee.lastName
  }

  return fullName.trim()
}

export function getEmployeeTeaserImage(employee: ContentfulEmployee) {
  return employee.teaserImage ?? employee.heroImage ?? null
}

export function getShouldLinkToEmployee(employee: ContentfulEmployee) {
  return employee.introduction !== null && employee.introduction !== undefined
}

export function sortEmployees(employees: ContentfulEmployee[]) {
  employees.sort((a, b) => {
    const lowerFirstnameA = String(a.firstName).toLowerCase()
    const lowerFirstnameB = String(b.firstName).toLowerCase()

    if (lowerFirstnameA === lowerFirstnameB) {
      return String(a.lastName).toLowerCase() < String(b.lastName).toLowerCase()
        ? -1
        : 1
    }

    return lowerFirstnameA < lowerFirstnameB ? -1 : 1
  })
}
