import classNames from 'classnames'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React, { useEffect, useRef, useState } from 'react'

import { ContentfulAsset } from '../../../@import-types/contentful/Asset.types'
import Colors from '../../../constants/Colors'
import useAnimationLoop from '../../../hooks/useAnimationLoop'
import useElementBounds from '../../../hooks/useElementBounds'
import useIsDesktop from '../../../hooks/useIsDesktop'
import useIsInViewport from '../../../hooks/useIsInViewport'
import Blob from '../../../utils/blob-classes/Blob'
import SpringPoint from '../../../utils/blob-classes/SpringPoint'
import {
  getColorByName,
  getComplementaryColor,
  isDarkHexColor,
} from '../../../utils/colorUtils'
import LayoutContainer from '../../_layouts/LayoutContainer'

import { tracking } from './../../_functional/Segment/tracking'

import styles from './video-block.module.scss'

export const VideoBlockTypename = 'ContentfulBlockVideo'

export interface VideoBlockProps {
  __typename: typeof VideoBlockTypename

  title?: string | undefined
  topic?: string | null
  topicSubtitle?: string | null

  color?: string | null

  video?: ContentfulAsset | null
  posterImage?: ContentfulAsset | null
}

//---- Video segment tracking
let progressInterval: any

function startVideoTracking(this: unknown, video: HTMLVideoElement) {
  video.addEventListener('play', trackVideoStart.bind(this, video))
  video.addEventListener('pause', trackVideoPause.bind(this, video))
}

function trackVideoStart(video: HTMLVideoElement) {
  tracking.track('Video Started', {
    category: 'Engagement',
    video_src: video.currentSrc,
  })
  progressInterval = setInterval(function () {
    const percentage = Math.round((video.currentTime / video.duration) * 100)
    if (percentage % 25 == 0 && percentage > 24) {
      // track every 25% watched video
      tracking.track('Video Watched', {
        video_src: video.currentSrc.split('/').pop(),
        percentage: percentage,
      })
      if (percentage >= 99) {
        clearInterval(progressInterval)
      }
    }
  }, 1000)
}

function trackVideoPause(video: HTMLVideoElement) {
  clearInterval(progressInterval)
  const percentage = Math.round((video.currentTime / video.duration) * 100)
  
  tracking.track('Video Stopped', {
    video_src: video.currentSrc,
    percentage: percentage,
  })
}
//--------

export default function VideoBlock({
  title,
  topic,
  topicSubtitle,
  color,
  video,
  posterImage,
}: VideoBlockProps) {
  const [isActive, setIsActive] = useState(false)
  const videoElRef = useRef<HTMLVideoElement>(null)
  const svgRef = useRef<SVGSVGElement>(null)
  const pathRef = useRef<SVGPathElement>(null)
  const iconGroupRef = useRef<SVGGElement>(null)

  const isDesktop = useIsDesktop(false)

  const { elementRef: rootElementRef, isInViewport } =
    useIsInViewport<HTMLDivElement>()

  const { elementRef: containerElRef, elementBoundsRef } =
    useElementBounds<HTMLDivElement>(false)

  const [blob] = useState(
    () =>
      new Blob({
        radius: 130,
        disappearRadius: 80,
        pointCount: 10,
        pointRadiusOffset: 0.15,
        pointSpeedOffset: 0.002,
      })
  )
  const [position] = useState(() => new SpringPoint(100, 8))

  const { start, stop } = useAnimationLoop((time) => {
    const pathEl = pathRef.current
    if (!pathEl) {
      return
    }

    blob.update(time)
    position.update(time.elapsedS)

    pathEl.setAttribute('d', blob.toSvgPathD())
    pathEl.setAttribute('transform', `translate(${position.x} ${position.y})`)
    iconGroupRef.current?.setAttribute(
      'transform',
      `translate(${position.x} ${position.y})`
    )
  })

  useEffect(() => {
    if (isInViewport) {
      centerBlob(true)
      start()
    } else {
      stop()
    }
  }, [isInViewport])

  useEffect(() => {
    svgRef.current?.setAttribute(
      'viewBox',
      `0 0 ${elementBoundsRef.current.domRect?.width} ${elementBoundsRef.current.domRect?.height}`
    )
  }, [elementBoundsRef.current.domRect])

  useEffect(() => {
    if (videoElRef?.current) {
      startVideoTracking(videoElRef.current)
    }
  }, [])

  const playBackgroundColor = getColorByName(color)
  const playIconColor = isDarkHexColor(playBackgroundColor)
    ? Colors.White
    : getComplementaryColor(playBackgroundColor)

  function centerBlob(instant = false) {
    position.targetX =
      elementBoundsRef.current.domRect!.width * (isDesktop ? 0.5 : 0.75)
    position.targetY = elementBoundsRef.current.domRect!.height * 0.5

    if (instant) {
      position.x = position.targetX
      position.y = position.targetY
    }
  }

  function onPlayClick() {
    if (isActive === true) {
      setIsActive(false)
      stop()
      return
    }
    if (isActive === false) {
      setIsActive(true)
      videoElRef.current?.play()
    }
  }

  function onMouseEnter(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    position.updateTargetFromMouseEvent(event)
    blob.appear()
  }

  function onMouseLeave() {
    blob.disappear()
    centerBlob()
  }

  function onMouseMove(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    position.updateTargetFromMouseEvent(event)
  }

  return (
    <LayoutContainer
      className={classNames(styles.container, isActive && styles.isActive)}
      forwardRef={rootElementRef}
      onClick={onPlayClick}
      withMargin
    >
      <div
        className={styles.innerContainer}
        ref={containerElRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
      >
        <div className={styles.textsContainer}>
          {title && <h3 className={styles.title}>{title}</h3>}
          {topic && <p className={styles.topic}>{topic}</p>}
          {topicSubtitle && (
            <p className={styles.topicSubtitle}>{topicSubtitle}</p>
          )}
        </div>

        {posterImage && posterImage.fluid && (
          <Img
            className={styles.image}
            fluid={posterImage.fluid}
            alt={posterImage.description || posterImage.title || ''}
          />
        )}

        {video && (
          <video
            className={styles.video}
            id="videoPlayer"
            src={video.file?.url}
            ref={videoElRef}
            controls
            title={title}
          />
        )}

        <svg className={styles.svg} ref={svgRef}>
          <path
            ref={pathRef}
            fill={playBackgroundColor}
            style={{ opacity: 0.8 }}
            d={blob.toSvgPathD()}
          />
          <g ref={iconGroupRef}>
            <path
              d="M17.5996 10.4999L0.949609 20.1128L0.94961 0.887019L17.5996 10.4999Z"
              fill={playIconColor}
              transform="translate(-9 -10.5)"
            />
          </g>
        </svg>
      </div>
    </LayoutContainer>
  )
}

export const videoBlockFragment = graphql`
  fragment VideoBlockFragment on Node {
    ... on ContentfulBlockVideo {
      __typename
      id
      title
      topic
      topicSubtitle
      color
      video {
        file {
          url
        }
      }
      posterImage {
        title
        description
        fluid(quality: 90, maxWidth: 1400) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`
