import { graphql } from 'gatsby'
import React from 'react'

import { ContentfulEmployee } from '../../../@import-types/contentful/Employee.types'
import EmployeeGrid from '../../_layouts/EmployeeGrid'

export const EmployeeGridBlockTypename = 'ContentfulBlockEmployeeGrid'

export interface EmployeeGridBlockProps {
  __typename: typeof EmployeeGridBlockTypename

  title?: string | null

  employees?: ContentfulEmployee[] | null
}

export default function EmployeeGridBlock({
  title,
  employees,
}: EmployeeGridBlockProps) {
  if (!employees) {
    return null
  }

  return <EmployeeGrid title={title} employees={employees} />
}

export const EmployeeGridBlockFragment = graphql`
  fragment EmployeeGridBlockFragment on Node {
    ... on ContentfulBlockEmployeeGrid {
      __typename

      title

      employees {
        ...EmployeeTeaserFragment
      }
    }
  }
`
