import { ContentfulAsset } from './Asset.types'
import { BasePageContent } from './BasePage.types'
import { Tag } from './general.types'

export const OurWorkPageTypename = 'ContentfulOurWorkPage'

export interface OurWorkPageType
  extends BasePageContent<typeof OurWorkPageTypename> {
  title?: string | null
  heroSubtitle?: string | null
  heroFilterPreamble?: string | null

  navigationTitle?: string | null

  teaserTitle?: string | null
  teaserDescription?: string | null
  teaserColor?: string | null

  teaserImage?: ContentfulAsset | null

  showCasesWithTags?: Tag[] | null

  featuredCases?: Array<{ id: string }> | null
  sortingOrder?: Array<{ id: string }> | null
}
