import React, { MutableRefObject } from 'react'

import useFormElementId from '../../../../hooks/forms/useFormElementId'
import useOptionalArgumentRef from '../../../../hooks/useOptionalArgumentRef'
import BaseFormField from '../BaseFormField'
import { BaseFormFieldPublicProps } from '../BaseFormField/BaseFormField'

export interface InputFieldProps extends BaseFormFieldPublicProps {
  forwardRef?: MutableRefObject<HTMLInputElement>
}

export default function InputField({
  label,

  id,
  name,
  value,

  isOptional = false,

  className,

  forwardRef,

  ...inputProps
}: InputFieldProps & JSX.IntrinsicElements['input']) {
  const inputRef = useOptionalArgumentRef(forwardRef, null)
  const renderId = useFormElementId(id, name, label)

  return (
    <BaseFormField
      label={label}
      id={id}
      name={name}
      value={value}
      isOptional={isOptional}
    >
      <input
        id={renderId}
        name={name}
        value={value}
        ref={inputRef}
        required={!isOptional}
        {...inputProps}
      />
    </BaseFormField>
  )
}
