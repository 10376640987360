import { useMemo } from 'react'

export type ActionArrayItem = string | { text: string; argument: string }

const actionIndex = '[@]'

export default function useActionString(
  actionString: string | undefined | null
) {
  return useMemo(() => {
    if (!actionString) {
      return ['']
    }

    let indexString = actionString
    const actionsRegArray = actionString.match(
      /\[([^[]+)\](\([^)]*\))|\[(.*?)\]/g
    )

    actionsRegArray?.forEach(
      (action) =>
        (indexString = indexString.replace(action, `|${actionIndex}|`))
    )

    const actionStringArray = indexString.split('|')

    const actionArray: ActionArrayItem[] = actionStringArray
      .map((item) => {
        if (item !== actionIndex) {
          return item
        }

        const action = actionsRegArray && actionsRegArray.shift()
        return action ? getTextAndLinkFromAction(action) : ''
      })
      .filter((item) => item !== '')

    return actionArray
  }, [actionString])
}

export function useJoinedActionString(
  actionString: string,
  actionCallback: (text?: string, argument?: string) => string | undefined
) {
  const actionList = useActionString(actionString)
  return useMemo(() => joinActionString(actionList, actionCallback), [
    actionList,
    actionCallback,
  ])
}

function getTextAndLinkFromAction(action: string) {
  const textMatch = action.match(/\[(.*?)\]/)
  const argumentMatch = action.match(/\((.*?)\)/)

  const text = textMatch && textMatch[1] ? textMatch[1] : ''
  const argument = argumentMatch && argumentMatch[1] ? argumentMatch[1] : ''

  return { text, argument }
}

export function joinActionString(
  actionStringArray: ActionArrayItem[],
  renderCallback: (text?: string, argument?: string) => string | undefined
) {
  if (!actionStringArray) {
    return ''
  }

  return actionStringArray
    .map((item) => {
      if (typeof item === 'string') {
        return item
      } else {
        return renderCallback(item?.text, item?.argument)
      }
    })
    .join('')
}
