import { graphql, Link } from 'gatsby'
import React from 'react'

import { Partner } from '../../../@import-types/contentful/Partner.types'
import useRootLink from '../../../hooks/routing/useRootLink'
import { createPageLink } from '../../../utils/routingUtils'
import { InternalLink } from '../../_elements/InternalLink/InternalLink'
import LayoutContainer from '../../_layouts/LayoutContainer'
import Heading2 from '../../_typography/Heading2'

import styles from './partners-block.module.scss'

export const PartnersBlockTypename = 'ContentfulBlockPartners'

export interface PartnersBlockProps {
  __typename: typeof PartnersBlockTypename
  title: string
  partners?: Partner[]
}

export default function PartnersBlock({ title, partners }: PartnersBlockProps) {
  const rootLink = useRootLink()

  if (!partners) {
    return null
  }

  return (
    <LayoutContainer withMargin>
      <Heading2>{title}</Heading2>

      <ul className={styles.list}>
        {partners.map((partner) => {
          const partnerPageLink = partner.page?.slug
            ? createPageLink(rootLink, partner.page.slug)
            : null

          const renderedPartnerName = (
            <div className={styles.itemName}>{partner.name}</div>
          )

          return (
            <li key={partner.name} className={styles.item}>
              {partnerPageLink && (
                <InternalLink
                  className={styles.itemLink}
                  to={partnerPageLink}
                  segmentdata={{
                    anchor_text: partner.name,
                    position: 'body',
                    url: partnerPageLink,
                  }}
                >
                  {renderedPartnerName}
                </InternalLink>
              )}

              {!partnerPageLink && renderedPartnerName}

              <div className={styles.itemType}>{partner.type}</div>
            </li>
          )
        })}
      </ul>
    </LayoutContainer>
  )
}

export const PartnersBlockFragment = graphql`
  fragment PartnersBlockFragment on Node {
    ... on ContentfulBlockPartners {
      title
      partners {
        name
        type
        link
        page {
          slug
        }
      }
    }
  }
`
