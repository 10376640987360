import { useCallback, useRef } from 'react'

import useOnUnmount from '../useOnUnmount'

export default function useRequestAnimationFrame() {
  const rafIdRef = useRef(-1)

  useOnUnmount(() => {
    cancelAnimationFrame(rafIdRef.current)
  })

  return useCallback(function boundRAF(callback: () => void) {
    cancelAnimationFrame(rafIdRef.current)
    rafIdRef.current = requestAnimationFrame(callback)
  }, [])
}

export function useDoubleRaf() {
  const rafIdRef = useRef(-1)

  useOnUnmount(() => {
    cancelAnimationFrame(rafIdRef.current)
  })

  return useCallback(function doubleRaf(callback: () => void) {
    cancelAnimationFrame(rafIdRef.current)
    rafIdRef.current = requestAnimationFrame(() => {
      rafIdRef.current = requestAnimationFrame(callback)
    })
  }, [])
}
