import classNames from 'classnames'
import React, { ReactNode, useLayoutEffect, useRef } from 'react'

import useElementBounds from '../../../hooks/useElementBounds'
import useWindowSize from '../../../hooks/useWindowSize'
import animateScroll from '../../../utils/animation/animateScroll'
import { easeInOutQuad } from '../../../utils/animation/easings'
import createAnchorId from '../../../utils/createAnchorId'
import { InternalLink } from '../../_elements/InternalLink/InternalLink'
import SvgArrowDown from '../../_svg/SvgArrowDown'
import Heading2 from '../../_typography/Heading2'
import LayoutContainer from '../LayoutContainer'

import styles from './base-carousel.module.scss'

interface BaseCarouselProps {
  title?: string | null
  titleLinkTo?: string | null

  noArrows?: boolean

  className?: string

  children: ReactNode | ReactNode[]

  withMargin?: boolean
  withScrollbar?: boolean
  keepItemContainerCentered?: boolean
}

export default function BaseCarousel({
  title,
  titleLinkTo,

  noArrows = false,

  className,

  children,

  withMargin = true,
  withScrollbar = false,
  keepItemContainerCentered = false,
}: BaseCarouselProps) {
  useWindowSize()

  const itemsContainerRef = useRef<HTMLDivElement>(null)
  const { elementRef: scrollbarRef, elementBoundsRef: scrollbarElBoundsRef } =
    useElementBounds<HTMLDivElement>()

  const rafId = useRef(-1)

  useLayoutEffect(() => {
    const itemsContainerEl = itemsContainerRef.current
    const scrollbarEl = scrollbarRef.current
    if (itemsContainerEl && scrollbarEl) {
      const scrollbarInnerWidth =
        itemsContainerEl.scrollWidth -
        // Remove left offset because of the added margins of base carousel
        (scrollbarElBoundsRef.current.offset?.left ?? 0) * 2

      const scrollbarInnerEl = scrollbarEl.children[0] as HTMLElement
      scrollbarInnerEl.style.width = `${scrollbarInnerWidth}px`
    }
  })

  function onPreviousClick() {
    setDeltaIndex(-1)
  }

  function onNextClick() {
    setDeltaIndex(1)
  }

  function setDeltaIndex(deltaDirection: number) {
    const containerEl = itemsContainerRef.current
    const children = containerEl?.children as HTMLCollectionOf<HTMLElement>

    if (!containerEl || children?.length < 2) {
      return
    }

    const { max, min, floor } = Math

    const firstChild = children[0]
    const secondChild = children[1]

    const carouselMargin = firstChild.offsetLeft
    const itemWidth = firstChild.clientWidth
    const itemMargin = secondChild.offsetLeft - carouselMargin - itemWidth

    const viewportWidth = containerEl.clientWidth - carouselMargin * 2
    const currentScroll = containerEl.scrollLeft
    const scrollWidth = containerEl.scrollWidth

    let nextScrollLeft: number

    const currentNoneRoundedIndex = currentScroll / (itemWidth + itemMargin)

    if (deltaDirection > 0) {
      const nextIndex = floor(
        currentNoneRoundedIndex +
          (viewportWidth + itemMargin) / (itemWidth + itemMargin)
      )

      nextScrollLeft = getScrollLeftForIndex(nextIndex)
    } else {
      const currentRoundedIndex = floor(currentNoneRoundedIndex)
      nextScrollLeft = getScrollRightForIndex(currentRoundedIndex)
    }

    animateScroll(containerEl, undefined, nextScrollLeft, 600, easeInOutQuad)

    function getScrollLeftForIndex(index: number) {
      const maxScrollLeft = scrollWidth - (viewportWidth - itemWidth)
      return min(maxScrollLeft, index * (itemWidth + itemMargin))
    }

    function getScrollRightForIndex(index: number) {
      return max(0, getScrollLeftForIndex(index) - (viewportWidth - itemWidth))
    }
  }

  function onItemsContainerScroll() {
    cancelAnimationFrame(rafId.current)
    rafId.current = requestAnimationFrame(() => {
      scrollbarRef.current!.scrollLeft = itemsContainerRef.current!.scrollLeft
    })
  }

  function onScrollbarScroll() {
    cancelAnimationFrame(rafId.current)
    rafId.current = requestAnimationFrame(() => {
      itemsContainerRef.current!.scrollLeft = scrollbarRef.current!.scrollLeft
    })
  }

  const shouldRenderHeader = Boolean(title) || noArrows === false

  return (
    <LayoutContainer
      className={classNames(styles.container, className)}
      withMargin={withMargin}
    >
      {shouldRenderHeader && (
        <div className={styles.headerContainer}>
          {title && (
            <Heading2 id={createAnchorId(title)}>
              {titleLinkTo ? (
                <InternalLink
                  to={titleLinkTo}
                  segmentdata={{
                    anchor_text: title,
                    position: 'body',
                    url: titleLinkTo,
                  }}
                >
                  {title}
                </InternalLink>
              ) : (
                title
              )}
            </Heading2>
          )}

          {!noArrows && (
            <div className={styles.buttonsContainer}>
              <button className={styles.buttonLeft} onClick={onPreviousClick}>
                <SvgArrowDown className={styles.buttonLeftIcon} />
              </button>
              <button className={styles.buttonRight} onClick={onNextClick}>
                <SvgArrowDown className={styles.buttonRightIcon} />
              </button>
            </div>
          )}
        </div>
      )}

      <div
        className={classNames(
          styles.itemsContainer,
          keepItemContainerCentered && styles.keepItemContainerCentered
        )}
        ref={itemsContainerRef}
        onScroll={onItemsContainerScroll}
      >
        {children}
      </div>

      <div
        className={classNames(
          styles.scrollbar,
          withScrollbar && styles.withScrollbar
        )}
        ref={scrollbarRef}
        onScroll={onScrollbarScroll}
      >
        <div className={styles.scrollbarInner} />
      </div>
    </LayoutContainer>
  )
}
