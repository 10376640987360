import React, { MutableRefObject, useEffect } from 'react'

import useFormElementId from '../../../../hooks/forms/useFormElementId'
import useOptionalArgumentRef from '../../../../hooks/useOptionalArgumentRef'
import BaseFormField from '../BaseFormField'
import { BaseFormFieldPublicProps } from '../BaseFormField/BaseFormField'

import styles from './text-area-field.module.scss'

interface TextAreaFieldProps extends BaseFormFieldPublicProps {
  forwardRef?: MutableRefObject<HTMLTextAreaElement>
}

export default function TextAreaField({
  label,

  id,
  name,
  value,

  isOptional,

  forwardRef,

  ...textAreaProps
}: TextAreaFieldProps & JSX.IntrinsicElements['textarea']) {
  const textAreaRef = useOptionalArgumentRef(forwardRef, null)
  const renderId = useFormElementId(id, name, label)

  useEffect(() => {
    const textAreaElement = textAreaRef.current

    if (!textAreaElement) {
      return
    }

    // Reset height
    textAreaElement.style.height = '1px'

    // Measure dynamic height
    const textAreaHeight = textAreaElement.scrollHeight

    // Set new height
    textAreaElement.style.height = `${textAreaHeight + 1}px`
  })

  return (
    <BaseFormField
      label={label}
      id={id}
      name={name}
      value={value}
      isOptional={isOptional}
    >
      <textarea
        className={styles.textArea}
        id={renderId}
        name={name}
        value={value}
        ref={textAreaRef}
        {...textAreaProps}
      />
    </BaseFormField>
  )
}
