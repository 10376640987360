import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

import { ContentfulAsset } from '../../../@import-types/contentful/Asset.types'
import createAnchorId from '../../../utils/createAnchorId'
import ContentfulRichText from '../../_elements/ContentfulRichText'
import LayoutContainer from '../../_layouts/LayoutContainer'
import RichTextLayout from '../../_layouts/RichTextLayout'
import SideColumnLayout from '../../_layouts/SideColumnLayout'
import Heading2 from '../../_typography/Heading2'

import styles from './text-block.module.scss'

export const TextBlockTypename = 'ContentfulBlockText'

export interface TextBlockProps {
  __typename: typeof TextBlockTypename
  title: string
  bodyText: {
    json: any
  }

  renderTitleAsQuote?: boolean | null

  image?: ContentfulAsset | null
}

export default function TextBlock({
  title,
  bodyText,

  renderTitleAsQuote,

  image,
}: TextBlockProps) {
  const hasImage = Boolean(image && image.fluid)

  return (
    <LayoutContainer withMargin>
      <SideColumnLayout
        mainColumnClassName={hasImage && styles.mainColumnWithImage}
        sideColumn={
          title && (
            <>
              {renderTitleAsQuote ? (
                <blockquote className={styles.quoteTitle}>{title}</blockquote>
              ) : (
                title && <Heading2 id={createAnchorId(title)}>{title}</Heading2>
              )}
            </>
          )
        }
        mainColumn={
          <>
            {hasImage && (
              <Img
                className={styles.image}
                fluid={image!.fluid}
                alt={image!.description ?? image!.title ?? ""}
              />
            )}

            <RichTextLayout className={styles.textContent}>
              {bodyText && <ContentfulRichText json={bodyText.json} />}
            </RichTextLayout>
          </>
        }
      />
    </LayoutContainer>
  )
}

export const textBlockFragment = graphql`
  fragment TextBlockFragment on Node {
    ... on ContentfulBlockText {
      title
      bodyText {
        json
      }

      renderTitleAsQuote

      image {
        title
        description
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`
