import classNames from 'classnames'
import { graphql } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'

import { StandardPageType } from '../../../@import-types/contentful/StandardPage.types'
import { InternalLink } from '../../_elements/InternalLink/InternalLink'
import SvgArrowRightLong from '../../_svg/SvgArrowRightLong/SvgArrowRightLong'

import styles from './reference-tiles-block.module.scss'

export const ReferenceTilesBlockTypename = 'ContentfulBlockReferenceTiles'

export interface ReferenceTilesBlockProps {
  __typename: typeof ReferenceTilesBlockTypename

  title?: string | undefined
  references?: StandardPageType[]
}

export default function ReferenceTilesBlock({
  title,
  references,
}: ReferenceTilesBlockProps) {
  const refLength = references?.length ?? 0
  const refAmount = refLength % 2 == 0 ? 'even' : 'odd'

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        {title && <h2>{title}</h2>}
        {references && (
          <div
            className={classNames(
              styles.tilesContainer,
              refAmount === 'odd' && styles.odd
            )}
          >
            {references.map((page) => {
              const url = '/' + page.slug
              return (
                <InternalLink
                  to={url}
                  className={styles.pageContainer}
                  segmentdata={{
                    anchor_text: page.navigationTitle,
                    position: 'body',
                    url: url,
                  }}
                  title={page.navigationTitle ?? page.title ?? ''}
                >
                  {(page.navigationTitle || page.title) && (
                    <h3 className={styles.pageHeading}>
                      {page.navigationTitle ?? page.title}
                    </h3>
                  )}
                  {page.teaserDescription && (
                    <p className={styles.pageText}>{page.teaserDescription}</p>
                  )}
                  <span className={styles.arrowContainer}>
                    <SvgArrowRightLong className={styles.arrow} />
                  </span>
                </InternalLink>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export const referenceTilesBlockFragment = graphql`
  fragment ReferenceTilesBlockFragment on Node {
    ... on ContentfulBlockReferenceTiles {
      __typename
      id
      title
      references {
        id
        slug
        title
        navigationTitle
        teaserDescription
      }
    }
  }
`
