import { graphql } from 'gatsby'
import React from 'react'

import { CTATargetId } from '../../../constants/GlobalDOM'
import CTAButton from '../../_elements/_buttons/CTAButton'
import LayoutContainer from '../../_layouts/LayoutContainer'

import { useIssueCollectorScript } from './JIRAIssueCollectorBlock.hooks'

import styles from './jira-issue-collector-block.module.scss'

export const JIRAIssueCollectorBlockTypename =
  'ContentfulBlockJiraIssueCollector'

export interface JIRAIssueCollectorBlockProps {
  __typename: typeof JIRAIssueCollectorBlockTypename

  issueSummary?: string | null
  buttonText?: string | null
  collectorId?: string | null
  locale?: string | null
  epicName?: string | null
  assignee?: string | null
}

export default function JIRAIssueCollectorBlock({
  issueSummary,
  buttonText,
  collectorId,
  locale,
  epicName,
  assignee,
}: JIRAIssueCollectorBlockProps) {
  const { isReady, triggerIssueCollector } = useIssueCollectorScript({
    issueSummary,
    collectorId,
    locale,
    epicName,
    assignee,
  })

  return (
    <LayoutContainer className={styles.container} withMargin>
      <CTAButton
        className={styles.button}
        id={CTATargetId}
        disabled={!isReady}
        onClick={triggerIssueCollector}
      >
        {buttonText}
      </CTAButton>
    </LayoutContainer>
  )
}

export const JIRAIssueCollectorBlockFragment = graphql`
  fragment JIRAIssueCollectorBlockFragment on Node {
    ... on ContentfulBlockJiraIssueCollector {
      __typename

      issueSummary
      buttonText
      collectorId
      locale
      epicName
      assignee
    }
  }
`
