import React from 'react'

interface SvgDownloadProps {}

export default function SvgDownload(
  props: SvgDownloadProps & JSX.IntrinsicElements['svg']
) {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" {...props}>
      <path
        d="M17.5 9.625L10.5 16.625L3.5 9.625"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 16.625V0.875"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.125 20.125H0.875"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
