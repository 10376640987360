import classNames from 'classnames'
import React from 'react'

import { JobRolePageContent } from '../../../@import-types/contentful/JobRolePage.types'
import useMicroCopy from '../../../hooks/globalContent/useMicroCopy'
import useJobPageLink from '../../../hooks/routing/useJobPageLink'
import BaseDate from '../../_elements/BaseDate'
import { InternalLink } from '../../_elements/InternalLink/InternalLink'
import ActionString from '../../_functional/ActionString'
import SvgArrowRight from '../../_svg/SvgArrowRight'
import SvgArrowRightSmall from '../../_svg/SvgArrowRightSmall'

import styles from './job-teaser-card.module.scss'

interface JobTeaserCardProps {
  job: JobRolePageContent

  className?: string
}

export default function JobTeaserCard({
  job,

  className,
}: JobTeaserCardProps) {
  const linkTo = useJobPageLink(job)

  const applyBeforeLabel = useMicroCopy('text.apply_before')

  const { title, teaserTitle, applyBeforeDate } = job

  const renderTitle = teaserTitle || title

  return (
    <InternalLink
      className={classNames(styles.container, className)}
      to={linkTo}
      segmentdata={{
        anchor_text: renderTitle,
        position: 'body',
        url: linkTo,
      }}
    >
      <span className={styles.title}>{renderTitle}</span>

      {applyBeforeDate && (
        <span className={styles.applyBefore}>
          <ActionString value={applyBeforeLabel}>
            {() => <BaseDate key="date" date={applyBeforeDate} />}
          </ActionString>
        </span>
      )}

      <SvgArrowRightSmall className={styles.arrow} />
    </InternalLink>
  )
}
