import { graphql } from 'gatsby'
import React from 'react'

import { AnyTeaser } from '../../../@import-types/contentful/general.types'
import usePageLink from '../../../hooks/routing/usePageLink'
import TeaserCardSwitch from '../../_cards/TeaserCardSwitch'
import BaseCarousel from '../../_layouts/BaseCarousel'

import styles from './teaser-carousel-block.module.scss'

export const TeaserCarouselBlockTypename = 'ContentfulBlockTeaserCarousel'

export interface TeaserCarouselBlockProps {
  __typename?: typeof TeaserCarouselBlockTypename

  title: string | null | undefined
  titleReference?: { slug: string | null | undefined } | null

  teasers: AnyTeaser[] | null | undefined

  className?: string
}

export default function TeaserCarouselBlock({
  title,
  titleReference,

  teasers,

  className,
}: TeaserCarouselBlockProps) {
  const titleReferendeLink = usePageLink(titleReference?.slug)

  return (
    <BaseCarousel
      className={className}
      title={title}
      titleLinkTo={titleReference ? titleReferendeLink : null}
    >
      {teasers?.map((teaserItem) => (
        <TeaserCardSwitch
          key={teaserItem.id}
          className={styles.teaserCard}
          teaser={teaserItem}
         
        />
        
      ))}
    </BaseCarousel>
  )
}

export const TeaserCarouselBlockFragment = graphql`
  fragment TeaserCarouselBlockFragment on Node {
    ... on ContentfulBlockTeaserCarousel {
      __typename
      id
      title

      titleReference {
        ... on ContentfulJoinUsPage {
          slug
        }

        ... on ContentfulOurWorkPage {
          slug
        }

        ... on ContentfulPageBlogListing {
          slug
        }
      }

      teasers {
        ... on ContentfulBlogPost {
          ...BlogPostTeaserFragment
        }

        ... on ContentfulCasePage {
          ...CaseTeaserFragment
        }

        ... on ContentfulJobRolePage {
          ...JobRolePageTeaserFragment
        }

        ... on ContentfulOurWorkPage {
          ...OurWorkTeaserFragment
        }

        ... on ContentfulPageBlogListing {
          ...BlogListingPageTeaserFragment
        }

        ... on ContentfulStandardPage {
          ...StandardPageTeaserFragment
        }
      }
    }
  }
`
