import { ContentfulAsset } from './Asset.types'
import { BasePageContent } from './BasePage.types'

export const AboutPageTypename = 'ContentfulAboutPage'

export interface AboutPageType
  extends BasePageContent<typeof AboutPageTypename> {
  title?: string | null
  navigationTitle?: string | null

  heroImage?: ContentfulAsset | null
  heroText?: string | null

  teaserTitle?: string | null
  teaserDescription?: string | null
  teaserColor?: string | null
  teaserImage?: ContentfulAsset | null
  heroPoster?: ContentfulAsset | null

  content?: any[] | null
}
