import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

import { ContentfulAsset } from '../../../@import-types/contentful/Asset.types'

import styles from './image-block.module.scss'

export const ImageBlockTypename = 'ContentfulBlockFullscreenImage'

export interface ImageBlockProps {
  __typename: typeof ImageBlockTypename
  title?: string | null
  image: ContentfulAsset
}

export default function ImageBlock({ title, image }: ImageBlockProps) {
  return (
    <section className={styles.container}>
      <Img
        className={styles.image}
        alt={title ?? image.description ?? image.title}
        fluid={image.fluid}
      />
    </section>
  )
}

export const imageBlockFragment = graphql`
  fragment ImageBlockFragment on Node {
    ... on ContentfulBlockFullscreenImage {
      title
      image {
        title
        description
        fluid(quality: 85, maxWidth: 2200) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`
