import { CaseTypename } from '../../@import-types/contentful/Case.types'
import { AnyTeaser } from '../../@import-types/contentful/general.types'
import { createCaseLink, createPageLink } from '../../utils/routingUtils'
import useGlobalStartPage from '../globalContent/useGlobalStartPage'

import useRootLink from './useRootLink'

export default function useTeaserLink(teaser: AnyTeaser) {
  const rootLink = useRootLink()
  const globalStartPage = useGlobalStartPage()

  switch (teaser.__typename) {
    case CaseTypename:
      return createCaseLink(rootLink, globalStartPage, teaser)

    default:
      return createPageLink(rootLink, teaser.slug)
  }
}
