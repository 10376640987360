import classNames from 'classnames'
import React from 'react'

import styles from './heading-3.module.scss'

interface Heading3Props {}

export default function Heading3({
  className,
  ...headingProps
}: Heading3Props & JSX.IntrinsicElements['h3']) {
  return (
    <h3 className={classNames(styles.heading3, className)} {...headingProps} />
  )
}
