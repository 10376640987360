import React from 'react'

interface SvgCrossProps {}

export default function SvgCross(
  props: SvgCrossProps & JSX.IntrinsicElements['svg']
) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        className="svg-stroke"
        d="M23.6777 6.32233L6 24"
        stroke="#2f210c"
        strokeWidth="2"
      />
      <path
        className="svg-stroke"
        d="M6.00007 6.32233L23.6777 24"
        stroke="#2f210c"
        strokeWidth="2"
      />
    </svg>
  )
}
