import classNames from 'classnames'
import React, { useRef } from 'react'

import useMicroCopy from '../../../hooks/globalContent/useMicroCopy'
import useAnimateElementHeight from '../../../hooks/useAnimateElementHeight'
import useIsDesktop from '../../../hooks/useIsDesktop'
import createAnchorId from '../../../utils/createAnchorId'
import ContentfulRichText from '../../_elements/ContentfulRichText'
import CTAButton from '../../_elements/_buttons/CTAButton'
import SvgArrowDown from '../../_svg/SvgArrowDown'
import Heading2 from '../../_typography/Heading2'
import RichTextLayout from '../RichTextLayout'

import styles from './document-teaser-text.module.scss'

export interface DocumentTeaserTextProps {
  title: string
  bodyText: {
    json: any
  }
  setDisplayDownloadForm: (value: boolean) => void
  displayDownloadForm: boolean
}

export default function DocumentTeaserText({
  title,
  bodyText,
  setDisplayDownloadForm,
  displayDownloadForm,
}: DocumentTeaserTextProps) {
  const CTAButtonLabel = useMicroCopy('CTA.getMaterial')
  const isDesktop = useIsDesktop()

  const handleClick = () => {
    setDisplayDownloadForm(true)
  }

  const containerElRef = useRef<HTMLDivElement>(null)
  useAnimateElementHeight(containerElRef)

  return (
    <>
      <RichTextLayout className={styles.textContent}>
        {isDesktop && (
          <Heading2 className={styles.title} id={createAnchorId(title)}>
            {title}
          </Heading2>
        )}
        <ContentfulRichText json={bodyText.json} />
      </RichTextLayout>

      <div
        className={classNames(
          styles.CTAButtonWrapper,
          displayDownloadForm && styles.hideButton
        )}
        ref={containerElRef}
      >
        <CTAButton
          onClick={handleClick}
          className={classNames(styles.CTAButton)}
          isSmaller={isDesktop}
        >
          <span className={styles.CTAButtonLabel}>
            {CTAButtonLabel}
            <SvgArrowDown className={styles.CTAButtonLabelArrow} />
          </span>
        </CTAButton>
      </div>
    </>
  )
}
