import React from 'react'

import { StandardPageType } from '../../../@import-types/contentful/StandardPage.types'
import Colors from '../../../constants/Colors'
import useTeaserLink from '../../../hooks/routing/useTeaserLink'
import BlobTeaserCard from '../BlobTeaserCard'

interface StandardPageTypeProps {
  standardPage: StandardPageType

  className?: string
}

export default function StandardPageTeaserCard({
  standardPage,

  className,
}: StandardPageTypeProps) {
  const standardPageLink = useTeaserLink(standardPage)

  return (
    <BlobTeaserCard
      className={className}
      to={standardPageLink}
      title={standardPage.teaserTitle}
      description={standardPage.teaserDescription}
      backgroundColor={standardPage.teaserColor ?? Colors.GreenLight}
      blobImage={standardPage.teaserImage}
    />
  )
}
